import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import Authentication from 'lib/Authentication';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { listConstructions } from 'services/Construction.service';

type Value = {
    isLoading: boolean,
    constructions: Construction.With<'client' | 'user' | 'follow_ups'>[],
    construction: Construction.With<'client' | 'user' | 'follow_ups'> | null,
    constructionId: Construction.Model['id'] | null,
    setConstructions: Dispatch<SetStateAction<Value['constructions']>>,
    setConstructionId: Dispatch<SetStateAction<Value['constructionId']>>,
    isCreateModalVisible: boolean,
    setIsCreateModalVisible: Dispatch<SetStateAction<Value['isCreateModalVisible']>>,
    isEditModalVisible: boolean,
    setIsEditModalVisible: Dispatch<SetStateAction<Value['isEditModalVisible']>>,
    isDetailModalVisible: boolean,
    setIsDetailModalVisible: Dispatch<SetStateAction<Value['isDetailModalVisible']>>,
    isFollowUpCreateModal: boolean,
    setIsFollowUpCreateModal: Dispatch<SetStateAction<Value['isFollowUpCreateModal']>>,
    isOverviewModule: boolean,
    fetchConstructions: () => Promise<void>,
};

type Props = { 
    isOverviewModule: boolean,
    children: (value: Value) => ReactNode,
};

const ConstructionContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function ConstructionContextProvider({ isOverviewModule, children }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [constructions, setConstructions] = useState<Value['constructions']>([]);
    const [constructionId, setConstructionId] = useState<Construction.Model['id'] | null>(null);
    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isDetailModalVisible, setIsDetailModalVisible] = useState(() => constructionId !== null);
    const [isFollowUpCreateModal, setIsFollowUpCreateModal] = useState<boolean>(false);

    const app = App.useApp();

    const fetchConstructions = async () => {
        setIsLoading(true);
        
        //TODO(142): Add server side pagination
        const clients = isOverviewModule
            ? null
            : Authentication.getCurrentClientId();

        const response = await listConstructions(clients);

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setConstructions(response.constructions);
    };

    const memoizedFetchConstructions = useCallback(fetchConstructions, [isOverviewModule, app]);

    useEffect(() => {
        memoizedFetchConstructions();
    }, [memoizedFetchConstructions]);

    const construction = useMemo(() => {
        if (!constructionId)
            return null;

        const found = constructions.find(construction => construction.id === constructionId);

        if (!found)
            throw new Error(`Could not find a construction with id ${constructionId}`);

        return found;
    }, [constructions, constructionId]);

    const value: Value = {
        isLoading,
        constructions,
        construction,
        constructionId,
        setConstructions,
        setConstructionId,
        isCreateModalVisible,
        setIsCreateModalVisible,
        isEditModalVisible,
        setIsEditModalVisible,
        isDetailModalVisible,
        setIsDetailModalVisible,
        isFollowUpCreateModal,
        setIsFollowUpCreateModal,
        isOverviewModule,
        fetchConstructions: memoizedFetchConstructions,
    };

    return (
        <ConstructionContext.Provider value={value}>
            {children(value)}
        </ConstructionContext.Provider>
    );
}

export function useConstruction() {
    const context = useContext(ConstructionContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `ConstructionContextProvider`.');

    return context;
}
