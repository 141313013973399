import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import Authentication from 'lib/Authentication';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { findAccount, getAccountSubscription, listAccountClients } from 'services/Account.service';

type Value = {
    isLoading: boolean,
    subscription: CobreFacil.Subscription | undefined,
    clientManager: ClientManager.Model,
    clients: Client.Model[],
    clientSelected: Client.Model | null,
    clientId: Client.Model['id'] | null,
    setClientId: Dispatch<SetStateAction<Value['clientId']>>,
    isCreateClientModalVisible: boolean,
    setIsCreateClientModalVisible: Dispatch<SetStateAction<Value['isCreateClientModalVisible']>>,
    isEditClientModalVisible: boolean,
    setIsEditClientModalVisible: Dispatch<SetStateAction<Value['isEditClientModalVisible']>>,
    isQrCodeModalVisible: boolean,
    setIsQrCodeModalVisible: Dispatch<SetStateAction<Value['isQrCodeModalVisible']>>,
    fetchClients: () => Promise<void>,
};

type Props = { children: (value: Value) => ReactNode };

const AccountContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function AccountContextProvider({ children }: Props) {
    const [isLoading, setIsLoading] = useState(false);
    const [subscription, setSubscription] = useState<CobreFacil.Subscription>();
    const [clientManager, setClientManager] = useState<ClientManager.Model>({} as ClientManager.Model);
    const [clients, setClients] = useState<Client.Model[]>([]);
    const [clientId, setClientId] = useState<Client.Model['id'] | null>(null);
    const [isCreateClientModalVisible, setIsCreateClientModalVisible] = useState(false);
    const [isEditClientModalVisible, setIsEditClientModalVisible] = useState(false);
    const [isQrCodeModalVisible, setIsQrCodeModalVisible] = useState(false);

    
    const app = App.useApp();

    const fetchClientManager = async () => {
        setIsLoading(true);

        const response = await findAccount();
        
        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setClientManager(response.client_manager);
    };

    const fetchClientManagerSubscription = async () => {
        setIsLoading(true);

        const response = await getAccountSubscription();
        
        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setSubscription(response.subscription);
    };

    const fetchClients = async () => {
        setIsLoading(true);

        const response = await listAccountClients();

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        await Authentication.syncAllowedClientsUser();
        setClients(response.clients);
    };

    const clientSelected = useMemo(() => {
        if (!clientId)
            return null;

        const found = clients.find(clients => clients.id === clientId);

        if (!found)
            throw new Error(`Could not find a clients with id ${clientId}`);

        return found;

    }, [clients, clientId]);

    const value: Value = {
        isLoading,
        subscription,
        clientManager,
        clients,
        clientSelected,
        clientId,
        setClientId,
        isCreateClientModalVisible,
        setIsCreateClientModalVisible,
        isEditClientModalVisible,
        setIsEditClientModalVisible,
        isQrCodeModalVisible,
        setIsQrCodeModalVisible,
        fetchClients: fetchClients,
    };

    useEffect(() => {
        fetchClientManagerSubscription();
        fetchClients();
        fetchClientManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <AccountContext.Provider value={value}>
            {children(value)}
        </AccountContext.Provider>
    );
}

export function useAccount() {
    const context = useContext(AccountContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `AccountContextProvider`.');

    return context;
}