import React, { useState } from 'react';

import {
    Button,
    Form,
    Input,
    Modal
} from 'antd';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useIssue } from 'lib/providers/IssueContextProvider';

export default function CreateChecklistModal() {
    const [form] = Form.useForm();
    const { setCreateChecklistModal, setChecklists } = useIssue();
    const [tasks, setTasks] = useState<string[]>([]);
    const [taskInput, setTaskInput] = useState<string>('');

    const close = () => {
        setCreateChecklistModal(false);
    };

    const addTask = () => {
        setTasks([...tasks, taskInput]);
        setTaskInput('');
    };

    const removeTask = (index: number) => {
        const updatedTasks = [...tasks];
        updatedTasks.splice(index, 1);
        setTasks(updatedTasks);
    };

    const onFinish = async () => {
        const values = await form.validateFields();

        const filteredTasks = tasks.filter((task) => task);

        const newChecklist = {
            id: values.name,
            name: values.name,
            tasks: filteredTasks.map((task) => ({
                description: task,
                type: 'pending',
                justification: '',
            })),
        };

        setChecklists(prevState => [...prevState, newChecklist]);

        close();
    };

    const isValidChecklist = form.getFieldValue('name') !== undefined && tasks.filter((task) => task).length !== 0;

    return (
        <Modal
            centered
            title="Criar checklist"
            destroyOnClose={true}
            onCancel={close}
            okText="Salvar"
            okButtonProps={{ disabled: !isValidChecklist }}
            open={true}
            onOk={onFinish}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>

                <Form.Item name="name" label="Título do checklist" rules={[{ required: true, message: 'Descreva o nome do checklist' }]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Tarefas" rules={[{ required: true, message: 'Defina uma tarefa' }]}>

                    {tasks.map((task, index) => (
                        <div key={index} style={{ marginBottom: '8px', display: 'flex', alignItems: 'center' }}>
                            <Input
                                value={task}
                                onChange={(e) => {
                                    const updatedTasks = [...tasks];
                                    updatedTasks[index] = e.target.value;
                                    setTasks(updatedTasks);
                                }}
                                style={{ marginRight: '8px', flex: 1 }}
                            />
                            <DeleteOutlined
                                style={{ color: 'red', cursor: 'pointer' }}
                                onClick={() => removeTask(index)}
                            />
                        </div>
                    ))}

                    <div style={{ marginBottom: '8px' }}>
                        <Button type="dashed" onClick={addTask} style={{ width: '100%' }} icon={<PlusOutlined />}>
                            Tarefa
                        </Button>
                    </div>
                </Form.Item>

            </Form>
        </Modal>
    );
}
