import React, { Fragment } from 'react';

import { Button, Card, Col, Row, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { CreateVehicleModal } from 'components/Vehicle/CreateVehicleModal';
import { EditVehicleModal } from 'components/Vehicle/EditVehicleModal';
import { VehiclesFilterBar } from 'components/Vehicle/VehicleFilterBar';
import { VehicleContextProvider } from 'lib/providers/VehicleContextProvider';
import { Show } from 'lib/Show';

import { Columns } from './Columns';


export function Vehicle() {
    return (
        <VehicleContextProvider>
            {({
                vehicles,
                isLoading,
                isCreateModalVisible,
                setIsCreateModalVisible,
                isEditModalVisible,
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <Typography.Title level={3}>
                                Veículos
                            </Typography.Title>

                            <Button
                                type="primary"
                                onClick={() => setIsCreateModalVisible(true)}
                            >
                                Cadastrar
                            </Button>
                        </Row>

                        <Row>
                            <Col style={{ paddingRight: 0 }} span={24}>
                                <Card>
                                    <VehiclesFilterBar/>

                                    <List
                                        columns={Columns}
                                        data={vehicles}
                                        loading={isLoading}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Content>

                    <Show when={isCreateModalVisible}>
                        <CreateVehicleModal />
                    </Show>

                    <Show when={isEditModalVisible}>
                        <EditVehicleModal />
                    </Show>
                </Fragment>
            )}
        </VehicleContextProvider>
    );
}
