import Authentication from 'lib/Authentication';

type Profile = User.Model['profile'];

export type Module = typeof MODULE[keyof typeof MODULE];

export type Feature = typeof FEATURE[keyof typeof FEATURE];

export const PROFILE = {
    ADMIN: 'admin',
    CONCIERGE: 'concierge',
    CARETAKER: 'caretaker',
    MEASUREMENT: 'measurement',
    MANAGER: 'manager',
    ISSUE: 'issue',
    ISSUE_MAINTENANCE: 'issue_maintenance',
    ADVISOR: 'advisor',
} as const satisfies Record<Uppercase<Profile>, Profile>;

export const MODULE = {
    APARTMENT: 'apartment',
    RESIDENT: 'resident',
    ISSUE: 'issue',
    MAINTENANCE: 'maintenance',
    NOTICE: 'notice',
    AMBIENT: 'ambient',
    RESERVE: 'reserve',
    CHATBOT: 'chatbot',
    SUPPLIER: 'supplier',
    DOCUMENT: 'document',
    CONSUMPTION: 'consumption',
    USER: 'user',
    CONSTRUCTION: 'construction',
    SCHEDULE: 'schedule',
    REPORT: 'report',
} as const;

export const FEATURE = {
    'RESERVE::READ_PENDING_RESERVES': `${MODULE.RESERVE}::read_pending_reserves`,
    'RESIDENT::WRITE_RESIDENT': `${MODULE.RESERVE}::write_resident`,
    'RESIDENT::WRITE_VEHICLE': `${MODULE.RESERVE}::write_vehicle`,
    'USER::RESET_PASSWORD': `${MODULE.USER}::reset_password`,
    'ISSUE::WRITE': `${MODULE.ISSUE}::write`,
    'MAINTENANCE::WRITE': `${MODULE.MAINTENANCE}::write`,
    'CONSTRUCTION::WRITE': `${MODULE.CONSTRUCTION}::write`,
    'SCHEDULE::WRITE': `${MODULE.SCHEDULE}::write`,
} as const;

export const ADMIN_USER_MODULES: Module[] = [
    MODULE.APARTMENT,
    MODULE.RESIDENT,
    MODULE.ISSUE,
    MODULE.MAINTENANCE,
    MODULE.NOTICE,
    MODULE.AMBIENT,
    MODULE.RESERVE,
    MODULE.CHATBOT,
    MODULE.SUPPLIER,
    MODULE.DOCUMENT,
    MODULE.CONSUMPTION,
    MODULE.USER,
    MODULE.CONSTRUCTION,
    MODULE.SCHEDULE,
    MODULE.REPORT,
];

export const CONCIERGE_USER_MODULES: Module[] = [MODULE.RESIDENT, MODULE.RESERVE];

export const MEASUREMENT_USER_MODULES: Module[] = [MODULE.CONSUMPTION];

export const CARETAKER_USER_MODULES: Module[] = [
    MODULE.APARTMENT,
    MODULE.ISSUE,
    MODULE.MAINTENANCE,
    MODULE.NOTICE,
    MODULE.RESERVE,
    MODULE.CONSUMPTION,
    MODULE.CONSTRUCTION,
    MODULE.SCHEDULE,
];

export const MANAGER_USER_MODULES: Module[] = [
    MODULE.APARTMENT,
    MODULE.RESIDENT,
    MODULE.ISSUE,
    MODULE.MAINTENANCE,
    MODULE.NOTICE,
    MODULE.AMBIENT,
    MODULE.RESERVE,
    MODULE.CHATBOT,
    MODULE.SUPPLIER,
    MODULE.DOCUMENT,
    MODULE.CONSUMPTION,
    MODULE.CONSTRUCTION,
    MODULE.SCHEDULE,
    MODULE.REPORT,
];

export const ISSUE_USER_MODULES: Module[] = [
    MODULE.ISSUE,
    MODULE.SCHEDULE,
];

export const ISSUE_MAINTENANCE_USER_MODULES: Module[] = [
    MODULE.ISSUE,
    MODULE.MAINTENANCE,
    MODULE.SCHEDULE,
];

export const ADVISOR_USER_MODULES: Module[] = [
    MODULE.ISSUE,
    MODULE.MAINTENANCE,
    MODULE.CONSTRUCTION,
    MODULE.SCHEDULE,
    MODULE.REPORT,
];

export const ADMIN_USER_FEATURES: Feature[] = [
    FEATURE['RESERVE::READ_PENDING_RESERVES'],
    FEATURE['RESIDENT::WRITE_RESIDENT'],
    FEATURE['RESIDENT::WRITE_VEHICLE'],
    FEATURE['USER::RESET_PASSWORD'],
    FEATURE['ISSUE::WRITE'],
    FEATURE['MAINTENANCE::WRITE'],
    FEATURE['CONSTRUCTION::WRITE'],
    FEATURE['SCHEDULE::WRITE'],
];

export const CONCIERGE_USER_FEATURES: Feature[] = [
    FEATURE['RESIDENT::WRITE_VEHICLE'],
];

export const MEASUREMENT_USER_FEATURES: Feature[] = [];

export const CARETAKER_USER_FEATURES: Feature[] = [
    FEATURE['RESERVE::READ_PENDING_RESERVES'],
    FEATURE['ISSUE::WRITE'],
    FEATURE['MAINTENANCE::WRITE'],
    FEATURE['CONSTRUCTION::WRITE'],
    FEATURE['SCHEDULE::WRITE'],
];

export const MANAGER_USER_FEATURES: Feature[] = [
    FEATURE['RESERVE::READ_PENDING_RESERVES'],
    FEATURE['RESIDENT::WRITE_RESIDENT'],
    FEATURE['RESIDENT::WRITE_VEHICLE'],
    FEATURE['ISSUE::WRITE'],
    FEATURE['MAINTENANCE::WRITE'],
    FEATURE['CONSTRUCTION::WRITE'],
    FEATURE['SCHEDULE::WRITE'],
];

export const ISSUE_USER_FEATURES: Feature[] = [
    FEATURE['ISSUE::WRITE'],
    FEATURE['SCHEDULE::WRITE'],
];

export const ISSUE_MAINTENANCE_USER_FEATURES: Feature[] = [
    FEATURE['ISSUE::WRITE'],
    FEATURE['MAINTENANCE::WRITE'],
    FEATURE['SCHEDULE::WRITE'],
];

export const ADVISOR_USER_FEATURES: Feature[] = [];

function isValidProfile(value: any): value is Profile {
    const isValid = Object
        .values(PROFILE)
        .includes(value);

    return isValid;
};

function isValidModule(value: any): value is Module {
    const isValid = Object
        .values(MODULE)
        .includes(value);

    return isValid;
};

function translateProfile(profile: Profile): string {
    const translations: Record<Profile, string> = {
        [PROFILE.ADMIN]: 'Administrador',
        [PROFILE.CONCIERGE]: 'Portaria',
        [PROFILE.CARETAKER]: 'Zelador',
        [PROFILE.MEASUREMENT]: 'Medição',
        [PROFILE.MANAGER]: 'Gestor',
        [PROFILE.ISSUE]: 'Chamados',
        [PROFILE.ISSUE_MAINTENANCE]: 'Chamados e manutenções',
        [PROFILE.ADVISOR]: 'Conselho',
    } as const;

    const translation = translations[profile];

    return translation;
};

function getFeatureModule(feature: Feature): Module
{
    const module = feature.split('::')[0];

    if (!isValidModule(module))
        throw new Error(`Invalid module was provided. The provided module was ${module}`);

    return module as Module;
}

function getAllowedModules(profile: User.Model['profile']): Module[] {
    const profiles: Record<User.Model['profile'], Module[]> = {
        [PROFILE.ADMIN]: ADMIN_USER_MODULES,
        [PROFILE.CONCIERGE]: CONCIERGE_USER_MODULES,
        [PROFILE.CARETAKER]: CARETAKER_USER_MODULES,
        [PROFILE.MEASUREMENT]: MEASUREMENT_USER_MODULES,
        [PROFILE.MANAGER]: MANAGER_USER_MODULES,
        [PROFILE.ISSUE]: ISSUE_USER_MODULES,
        [PROFILE.ISSUE_MAINTENANCE]: ISSUE_MAINTENANCE_USER_MODULES,
        [PROFILE.ADVISOR]: ADVISOR_USER_MODULES,
    } as const;

    const modules = profiles[profile];

    return modules;
}

function hasModuleAccess(module: Module, modules: Module[]): boolean {
    const hasAccess = modules.includes(module);

    return hasAccess;
}

function getAllowedFeatures(profile: User.Model['profile']): Feature[] {
    const profiles: Record<User.Model['profile'], Feature[]> = {
        [PROFILE.ADMIN]: ADMIN_USER_FEATURES,
        [PROFILE.CONCIERGE]: CONCIERGE_USER_FEATURES,
        [PROFILE.CARETAKER]: CARETAKER_USER_FEATURES,
        [PROFILE.MEASUREMENT]: MEASUREMENT_USER_FEATURES,
        [PROFILE.MANAGER]: MANAGER_USER_FEATURES,
        [PROFILE.ISSUE]: ISSUE_USER_FEATURES,
        [PROFILE.ISSUE_MAINTENANCE]: ISSUE_MAINTENANCE_USER_FEATURES,
        [PROFILE.ADVISOR]: ADVISOR_USER_FEATURES,
    } as const;

    const features = profiles[profile];

    return features;
}

/** @private */
function hasFeatureAccess(feature: Feature, features: Feature[]): boolean {
    const hasAccess = features.includes(feature);

    return hasAccess;
}

function hasAccess(
    feature: Feature,
    profile: User.Model['profile'] = Authentication.getUserProfile(),
): boolean {
    const module = getFeatureModule(feature);
    const allowedModules = getAllowedModules(profile);

    if (!hasModuleAccess(module, allowedModules))
        return false;

    const allowedFeatures = getAllowedFeatures(profile);

    if (!hasFeatureAccess(feature, allowedFeatures))
        return false;
    
    return true;
};

export const Authorization = { 
    isValidProfile, 
    isValidModule,
    translateProfile,
    getFeatureModule,
    getAllowedModules,
    hasModuleAccess,
    getAllowedFeatures,
    hasAccess,
};

