import React from 'react';

import {
    Button,
    Divider,
    Input,
    notification,
    Popconfirm,
    Row, Space,
    Tag,
    Typography
} from 'antd';

import {
    CarOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    SearchOutlined,
    SecurityScanOutlined
} from '@ant-design/icons';
import API from 'lib/API';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { Show } from 'lib/Show';
import { generalReportResident } from 'services/ResidentService';

import { ResidentInterface } from '../_Common/_Interfaces/Resident';
import Content from '../_Common/_Layout/Content';
import List from '../_Common/List';
import FormResident from './FormResident';
import ListLog from './Logs/ListLog';
import ListPet from './Pet/ListPet';
import ListVehicle from './Vehicle/ListVehicle';

type State = {
    residents: Array<ResidentInterface>,
    residentsFiltered: Array<ResidentInterface>,
    resident: ResidentInterface
    showFormResident: Boolean,
    showListVehicle: Boolean,
    showListPet: Boolean,
    showListLog: Boolean,
    loading: Boolean,
    hasDevices: boolean,
    syncing: Boolean,
    searchText: string,
    open: Boolean,
    expoToken: Boolean
};

type Props = {
    history: any;
    match: any;
}

export default class ResidentIndex extends React.Component<Props, State> {

    state = {
        open: false,
        loading: true,
        syncing: false,
        residents: [],
        residentsFiltered: [],
        showFormResident: false,
        showListVehicle: false,
        showListPet: false,
        showListLog: false,
        resident: {} as ResidentInterface,
        searchText: '',
        hasDevices: false,
        expoToken: false
    };

    searchInput: any;

    getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node: any) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Pesquisar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />

                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                />
                <Divider type="vertical" />
                <Button onClick={() => this.handleReset(clearFilters)}
                    size="small" style={{ width: 90 }}
                    icon={<CloseCircleOutlined />} />

            </div>
        ),
        filterIcon: (filtered: any) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    });

    handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        this.setState({
            // eslint-disable-next-line array-callback-return
            residentsFiltered: this.state.residents.filter((r: ResidentInterface) => {
                if (dataIndex === 'name')
                    return r.name.toLowerCase().includes(selectedKeys[0].toLowerCase());
                else if (dataIndex === 'cpf')
                    return r.cpf.replace(/-/g, '').replace(/\./g, '').includes(selectedKeys[0].replace(/-/g, '').replace(/\./g, ''));
                else if (dataIndex === 'apartment') {
                    const apartmentSearch = `${r.apartment.tower.name} - ${r.apartment.name}`.toLowerCase();
                    return apartmentSearch.includes(selectedKeys[0].toLowerCase());
                }
                else if (dataIndex === 'vehicle') {
                    const vehicles = r.vehicles.map(vehicle => vehicle.board.toLowerCase());
                    const search = selectedKeys[0].toLowerCase();

                    return vehicles.find(v => v.includes(search)) ?? null;
                }
            })
        });
    };

    handleReset = (clearFilters: any) => {
        clearFilters();
        this.setState({ searchText: '', residentsFiltered: this.state.residents });
    };

    columns = [
        {
            title: 'Unidade',
            key: 'apartment', ...this.getColumnSearchProps('apartment'),
            render: (_: any, resident: ResidentInterface) => (`${resident.apartment.tower.name} - ${resident.apartment.name}`),
            sorter: (a: ResidentInterface, b: ResidentInterface) => {
                const first = `${a.apartment.tower.name} - ${a.apartment.name}`;
                const second = `${b.apartment.tower.name} - ${b.apartment.name}`;
                return first.localeCompare(second);
            },
            defaultSortOrder: 'ascend',
        },
        {
            title: 'Condômino', key: 'type', render: (_: any, resident: ResidentInterface) => (
                resident.is_approval_pending
                    ? <Tag color='red'>APROVAÇÃO PENDENTE</Tag>
                    : resident.apartment.is_in_debt
                        ? <Tag color='red'>INADIMPLENTE</Tag>
                        : resident.type === 'owner' && resident.isResident
                            ? <Tag color="success">PROPRIETÁRIO MORADOR</Tag>
                            : resident.type === 'owner'
                                ? <Tag color="success">PROPRIETÁRIO</Tag>
                                : resident.type === 'resident'
                                    ? <Tag color="processing">MORADOR</Tag>
                                    : resident.type === 'tenant'
                                        ? <Tag color="gold">INQUILINO</Tag>
                                        : resident.type === 'authorized_visitor'
                                            ? <Tag color="green">VISITANTE AUTORIZADO</Tag>
                                            : resident.type === 'local_employee'
                                                ? <Tag color="grey">FUNCIONÁRIO DO LOCAL</Tag>
                                                : resident.type === 'emergency_contact'
                                                    ? <Tag color="orange">CONTATO DE EMERGÊNCIA</Tag>
                                                    : <Tag color="default">Não informado</Tag>
            ),
            filters: [
                {
                    text: 'Proprietário',
                    value: 'owner',
                },
                {
                    text: 'Proprietário morador',
                    value: 'owner-resident',
                },
                {
                    text: 'Morador',
                    value: 'resident',
                },
                {
                    text: 'Inquilino',
                    value: 'tenant',
                },
                {
                    text: 'Aprovação pendente',
                    value: 'approval_pending',
                },
                {
                    text: 'Visitante autorizado',
                    value: 'authorized_visitor',
                },
                {
                    text: 'Funcionário do local',
                    value: 'local_employee',
                },
                {
                    text: 'Contato de emergência',
                    value: 'emergency_contact',
                },
                {
                    text: 'Inadimplente',
                    value: 'is_in_debt',
                },
                {
                    text: 'Não informado',
                    value: null
                }
            ],
            onFilter: (value: string, resident: ResidentInterface) => {
                if (value === 'owner-resident')
                    // eslint-disable-next-line eqeqeq
                    return resident.type == 'owner' && resident.isResident;
                else if (value === 'owner')
                    // eslint-disable-next-line eqeqeq
                    return resident.type == 'owner' && !resident.isResident;
                else if (value === 'approval_pending')
                    return resident.is_approval_pending;
                else if(value === 'is_in_debt')
                    return resident.apartment.is_in_debt;

                // eslint-disable-next-line eqeqeq
                return resident.type == value;
            },
            filterSearch: true,
        },
        { title: 'Nome', key: 'name', dataIndex: 'name', ...this.getColumnSearchProps('name') },
        { title: 'Contatos', key: 'phone', render: (_: any, resident: ResidentInterface) => (`${resident.phone ?? ''}\n${resident.cellphone ?? ''}`) },
        { title: 'Documentos', key: 'cpf', ...this.getColumnSearchProps('cpf'), render: (_: any, resident: ResidentInterface) => (`${resident.cpf ?? ''}\n${resident.rg ?? ''}`) },
        { title: 'Baixou o aplicativo?', key: 'expoToken', render: (_: any, resident: ResidentInterface) => (resident.expoToken ? 'Sim' : 'Não'),

            filters: [
                {
                    text: 'Baixou o App',
                    value: 'Sim',
                },
                {
                    text: 'Não baixou',
                    value: 'Não',
                },
            ],
        
            onFilter: (value: string, resident: ResidentInterface) => {
                if (value === 'Sim') {
                    return resident.expoToken;
                }
                return !resident.expoToken;
            },
            filterSearch: true,
        },

        {
            key: 'action',
            width: 230,
            ...this.getColumnSearchProps('vehicle'),
            render: (_: any, resident: ResidentInterface) => (
                <>
                    <Show when={Authorization.hasAccess(FEATURE['RESIDENT::WRITE_VEHICLE'])}>
                        <Button size='small' onClick={() => this.setState({ showListVehicle: true, resident: resident })} ><CarOutlined /></Button>
                        <Divider type="vertical" />
                    </Show>

                    <Show when={Authorization.hasAccess(FEATURE['RESIDENT::WRITE_RESIDENT'])}>
                        <Button
                            size='small'
                            onClick={() => this.setState({ showListPet: true, resident: resident })}
                        >
                            Pet
                        </Button>

                        <Divider type="vertical" />

                        <Button
                            size='small'
                            onClick={() => this.setState({ showListLog: true, resident: resident })}
                        >
                            <SecurityScanOutlined />
                        </Button>

                        <Divider type="vertical" />

                        <EditOutlined onClick={() => this.setState({ showFormResident: true, resident: resident })} />

                        <Divider type="vertical" />

                        <Popconfirm title={'Deseja remover?'}
                            placement="top"
                            onConfirm={() => this.onDelete(resident)}
                            cancelText={'Cancelar'}
                            okText={'Deletar'}
                            okType={'danger'}
                        >
                            <DeleteOutlined />
                        </Popconfirm>
                    </Show>
                </>)
        }
    ];

    fetchData = async () => {
        const residents = await API.get('/resident');
        this.setState({ residents, residentsFiltered: residents, loading: false });
    };

    onDelete = async (resident: ResidentInterface) => {
        this.setState({ loading: true });
        await API.delete(`/resident/${resident.id}`);
        await this.fetchData();
    };

    syncDevices = async () => {
        this.setState({ syncing: true });
        const syncResult = await API.post('/resident/sync');
        notification.open(syncResult);
        this.setState({ syncing: false });
    };

    hideModalForm = async (reload: boolean = false) => {
        this.setState({ showFormResident: false });

        if (reload) {
            this.setState({ loading: true });
            await this.fetchData();
        }
    };

    componentDidMount = async () => {
        await this.fetchData();
    };

    render() {

        const { showFormResident, showListVehicle, showListPet, showListLog, residentsFiltered, loading, resident, syncing, open, hasDevices } = this.state;

        return (
            <Content>
                <Row className='space-between' align="middle">

                    <Typography.Title level={3}>Condôminos</Typography.Title>
                    <Show when={Authorization.hasAccess(FEATURE['RESIDENT::WRITE_RESIDENT'])}>
                        <Space>
                            {
                                hasDevices
                                    ? <Popconfirm
                                        title="Por favor, atenção!"
                                        open={open}
                                        description={
                                            syncing
                                                ? 'Sincronizando...'
                                                : 'Deseja sincronizar dispositivos de acesso?'
                                        }
                                        onConfirm={() => this.syncDevices()}
                                        okButtonProps={{ loading: syncing }}
                                        okText="Sim"
                                        onCancel={() => this.setState({ open: false })}
                                    >
                                        <Button type="default" onClick={() => this.setState({ open: true })}>
                                            {
                                                syncing
                                                    ? 'Sincronizando...'
                                                    : 'Sincronizar dispositivos de acesso'
                                            }
                                        </Button>
                                    </Popconfirm>
                                    : null
                            }

                            <Button
                                type="default"
                                loading={syncing}
                                onClick={async () => {
                                    this.setState({ syncing: true });
                                    const response = await generalReportResident();

                                    if (response.success)
                                        window.open(response.url, '_blank');

                                    this.setState({ syncing: false });
                                }}
                            >
                                Relatório geral
                            </Button>

                            <Button
                                type="primary"
                                onClick={() => this.setState({ showFormResident: true, resident: {} as ResidentInterface })}
                            >
                                Cadastrar
                            </Button>
                        </Space>
                    </Show>
                </Row>
                {/* @ts-ignore */}
                <List data={residentsFiltered} loading={loading} columns={this.columns} />
                <FormResident isVisible={showFormResident} resident={resident} hideModalForm={this.hideModalForm} />
                <ListVehicle isVisible={showListVehicle} resident={resident} hideModalForm={() => this.setState({ showListVehicle: false, resident: {} as ResidentInterface })} />
                <ListPet isVisible={showListPet} resident={resident} hideModalForm={() => this.setState({ showListPet: false, resident: {} as ResidentInterface })} />
                <ListLog isVisible={showListLog} resident={resident} hideModalForm={() => this.setState({ showListLog: false, resident: {} as ResidentInterface })} />
            </Content>
        );
    }

}