import React, { Fragment, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Button, Card, Row, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { ConstructionsFilterBar } from 'components/Construction/ConstructionFilterBar';
import { CreateConstructionModal } from 'components/Construction/CreateConstructionModal';
import { DetailConstructionModal } from 'components/Construction/DetailConstructionModal';
import { EditConstructionModal } from 'components/Construction/EditConstructionModal';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { ConstructionContextProvider } from 'lib/providers/ConstructionContextProvider';
import { Show } from 'lib/Show';

import { Columns } from './Columns';

export function Constructions({ location }: RouteComponentProps) {
    const isOverviewModule = location.pathname.includes('/overview');

    const filteredColumns = useMemo(() => (
        !isOverviewModule
            ? Columns.filter(column => column.key !== 'clientName')
            : Columns
    ), [isOverviewModule]);

    return (
        <ConstructionContextProvider isOverviewModule={isOverviewModule}>
            {({
                constructions,
                isLoading,
                isCreateModalVisible,
                setIsCreateModalVisible,
                isEditModalVisible,
                isDetailModalVisible,
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <Typography.Title level={3}>
                                Obras
                            </Typography.Title>

                            <Show when={Authorization.hasAccess(FEATURE['CONSTRUCTION::WRITE'])}>
                                <Button
                                    type="primary"
                                    onClick={() => setIsCreateModalVisible(true)}
                                >
                                    Cadastrar
                                </Button>
                            </Show>
                        </Row>

                        <Card>
                            <ConstructionsFilterBar isOverviewModule={isOverviewModule} />
                            <List
                                data={constructions}
                                columns={filteredColumns}
                                loading={isLoading} />
                        </Card>
                    </Content>

                    <Show when={isCreateModalVisible}>
                        <CreateConstructionModal />
                    </Show>

                    <Show when={isEditModalVisible}>
                        <EditConstructionModal />
                    </Show>

                    <Show when={isDetailModalVisible}>
                        <DetailConstructionModal />
                    </Show>
                </Fragment>
            )}
        </ConstructionContextProvider>
    );
}
