import { Vehicle } from 'types/Vehicle';

import API from '../lib/API';
import { CreateVehicle, DeleteVehicle, ListAllVehicles, UpdateVehicle } from './contracts/Vehicle.contract';

const route = '/vehicle';

export function listAllVehicles(p0: {}): Promise<ListAllVehicles.Response> {
    return API.get(route);
}

export function createVehicle(body: CreateVehicle.Body): Promise<CreateVehicle.Response> {
    return API.post(route, body);
}

export function updateVehicle(id: Vehicle.Model['id'], body: UpdateVehicle.Body): Promise<UpdateVehicle.Response> {
    return API.put(`${route}/${id}`, body);
}

export function deleteVehicle(id: Vehicle.Model['id']): Promise<DeleteVehicle.Response> {
    return API.delete(`${route}/${id}`);
}