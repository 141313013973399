import dayjs from 'dayjs';

enum LocalStorageEnum {
    COBRE_FACIL_TOKEN = 'cobre_facil_token',
    COBRE_FACIL_CUSTOMER = 'cobre_facil_customer',
    COBRE_FACIL_PENDING_INVOICES = 'cobre_facil_pending_invoices'
};

const plans = [
    {
        'id': '8YVP5EKDYQ6L07NZ4L69',
        'description': 'Plano para administrar até 3 condomínios.',
        'limit': 3,
        'name': 'Plano até 3 condomínios',
        'price': '69.90'
    },
    {
        'id': 'V98EQ3WD7V7Y0145PYR6',
        'description': 'Plano para administrar até 3 condomínios.',
        'limit': 3,
        'name': 'Plano 1 - 2023',
        'price': '49.90'
    },
    {
        'id': '1WO74GZDWLVL0EQ6YXM8',
        'description': 'Plano para administrar até 5 condomínios.',
        'limit': 5,
        'name': 'Plano até 5 condomínios',
        'price': '99.90'
    },
    {
        'id': 'MRE2W6VJREK60N7POY48',
        'description': 'Plano para administrar até 5 condomínios.',
        'limit': 5,
        'name': 'Plano 2 - 2023',
        'price': '79.90'
    },
    {
        'id': '84P32XNDOVP809EYWGOZ',
        'description': 'Plano para administrar até 10 condomínios.',
        'limit': 10,
        'name': 'Plano até 10 condomínios',
        'price': '179.90'
    },
    {
        'id': 'GWNM64904LQE0P3LVX7R',
        'description': 'Plano para administrar até 10 condomínios.',
        'limit': 10,
        'name': 'Plano 3 - 2023',
        'price': '149.90'
    },
    {
        'id': 'LKV2PW803NV50REXMO61',
        'description': 'Plano para administrar até 20 condomínios.',
        'limit': 20,
        'name': 'Plano até 20 condomínios',
        'price': '349.90'
    },
    {
        'id': 'Z26X8GW05R64D4E317RN',
        'description': 'Plano para administrar até 20 condomínios.',
        'limit': 20,
        'name': 'Plano 4 - 2023',
        'price': '299.90'
    },
    {
        'id': 'R41KVL6D95RZJXW25YO7',
        'description': 'Plano para administrar até 30 condomínios.',
        'limit': 30,
        'name': 'Plano até 30 condomínios',
        'price': '519.90'
    },
    {
        'id': '932NW760QYM2DE584LGY',
        'description': 'Plano para administrar até 30 condomínios.',
        'limit': 30,
        'name': 'Plano 5 - 2023',
        'price': '449.90'
    },
    {
        'id': 'G5Y36MOD1NG8J7KE82NQ',
        'description': 'Plano para administrar até 40 condomínios.',
        'limit': 40,
        'name': 'Plano até 40 condomínios',
        'price': '679.90'
    },
    {
        'id': 'N8E7LQGJ8MWKJ1W2RV4P',
        'description': 'Plano para administrar até 40 condomínios.',
        'limit': 40,
        'name': 'Plano 6 - 2023',
        'price': '599.90'
    },
    {
        'id': 'WP8XZOQDMEOO051G7EYR',
        'description': 'Plano customizado para permitir o cadastro ilimitado de condomínios.',
        'limit': 200,
        'name': 'Plano ilimitado',
        'price': '679.90'
    },
    {
        'id': 'MRE2W6VJR6LY0N7POY48',
        'description': 'Plano para administrar até 50 condomínios.',
        'limit': 50,
        'name': 'Plano até 50 condomínios',
        'price': '799.90'
    },
    {
        'id': 'Z26X8GW052ZP04E317RN',
        'description': 'PRO até 3 condomínios',
        'limit': 3,
        'name': 'PRO até 3 condomínios',
        'price': '99.90'
    },
    {
        'id': 'R41KVL6D9PK3JXW25YO7',
        'description': 'PRO até 5 condomínios',
        'limit': 5,
        'name': 'PRO até 5 condomínios',
        'price': '149.90'
    },
    {
        'id': '932NW760QP6EDE584LGY',
        'description': 'PRO até 10 condomínios',
        'limit': 10,
        'name': 'PRO até 10 condomínios',
        'price': '219.90'
    },
    {
        'id': 'G5Y36MOD16PXJ7KE82NQ',
        'description': 'PRO + APP até 3 condomínios',
        'limit': 3,
        'name': 'PRO + APP até 3 condomínios',
        'price': '199.90'
    },
    {
        'id': 'N8E7LQGJ8Z9ND1W2RV4P',
        'description': 'PRO + APP até 5 condomínios',
        'limit': 5,
        'name': 'PRO + APP até 5 condomínios',
        'price': '249.90'
    }
];

class CobreFacil {

    findPlan(plan_id: string): CobreFacil.Plan | undefined {
        return plans.find(plan => plan.id === plan_id);
    }

    setInvoices(invoices: CobreFacil.Invoice[]): void {
        localStorage.setItem(LocalStorageEnum.COBRE_FACIL_PENDING_INVOICES, JSON.stringify(invoices));
    }

    getInvoices(): CobreFacil.Invoice[] {
        const invoices = localStorage.getItem(LocalStorageEnum.COBRE_FACIL_PENDING_INVOICES);

        if (invoices === null)
            return [];

        return JSON.parse(invoices);
    }

    haveDebts(): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const invoices = this.getInvoices().filter((invoice) =>
            dayjs().isAfter(dayjs(invoice.due_date))
            && dayjs().diff(dayjs(invoice.due_date), 'days') >= 10);

        // TODO pensar em como realizar melhor esse bloqueio
        return false;
    }

}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CobreFacil();