import React, { useState } from 'react';

import { App, Avatar, Button, Dropdown, MenuProps, Space, TableColumnsType, Tag, Tooltip } from 'antd';
import { useAppProps } from 'antd/es/app/context';

import { CopyOutlined, DeleteOutlined, EditOutlined, ReloadOutlined, RetweetOutlined, SendOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { getIssueDerivedStatus, getIssueDerivedStatusTagProps } from 'lib/helpers/Issue.helper';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { useIssue } from 'lib/providers/IssueContextProvider';
import { sleep } from 'lib/Sleep';
import { deleteIssue, reopenIssue, shareIssueWithResident } from 'services/Issue.service';

type Props = {
    issue: Issue.With<
        | 'user'
        | 'user_who_resolved'
        | 'user_responsible'
        | 'supplier'
        | 'issueType'
        | 'client'
    >
};

/** @factory */
const makeItems = (
    issue: Props['issue'],
    app: useAppProps,
    handleReopen: () => void,
    handleDuplicate: () => void,
    handleEdit: () => void,
    shareIssue: () => void,
    handleDelete: () => void,
    handleNewFollowUp: () => void,
): MenuProps['items'] => {
    const items: MenuProps['items'] = [];

    if (issue.status === 'closed')
        items.push({
            key: 'reopen',
            label: 'Reabrir',
            onClick: () => app.modal.confirm({
                title: 'Reabrir chamado',
                content: 'Tem certeza que deseja reabrir este chamado?',
                okText: 'Sim',
                okType: 'danger',
                onOk: handleReopen,
                cancelText: 'Não',
            }),
            icon: <RetweetOutlined />,
            danger: true,
        });

    if (issue.status !== 'closed')
        items.push(
            {
                key: 'edit',
                label: 'Editar',
                icon: <EditOutlined />,
                onClick: handleEdit,
            },
            {
                key: 'delete',
                label: 'Excluir',
                icon: <DeleteOutlined />,
                onClick: () => app.modal.confirm({
                    title: 'Excluir chamado',
                    content: 'Tem certeza que deseja excluir este chamado?',
                    okText: 'Sim',
                    okType: 'danger',
                    onOk: handleDelete,
                    cancelText: 'Não',
                }),
                danger: true,
            }
        );

    items.push(
        {
            key: 'changeStatus',
            label: 'Atualizar status',
            icon: <ReloadOutlined />,
            onClick: handleNewFollowUp
        },
        {
            key: 'share',
            label: 'Compartilhar',
            icon: <SendOutlined />,
            onClick: () => app.modal.confirm({
                title: 'Compartilhar chamado',
                content: `Tem certeza que deseja ${issue.share_with_resident ? 'remover' : 'conceder'} acesso aos condôminos via aplicativo?`,
                okText: 'Sim',
                okType: 'danger',
                onOk: shareIssue,
                cancelText: 'Não',
            }),
        },
        {
            key: 'duplicate',
            label: 'Duplicar',
            icon: <CopyOutlined />,
            onClick: handleDuplicate,
        }
    );

    return items;
};


const Actions = ({ issue }: Props) => {
    const [isSending, setIsSending] = useState<boolean>(false);

    const { 
        setIsDetailModalVisible,
        setIsEditModalVisible,
        setIsDuplicateModalVisible,
        setIssueId,
        fetchIssues,
        setIsFollowUpCreateModal
    } = useIssue();

    const app = App.useApp();

    const handleDetail = () => {
        setIssueId(issue.id);
        setIsDetailModalVisible(true);
    };

    const handleEdit = () => {
        setIssueId(issue.id);
        setIsEditModalVisible(true);
    };

    const handleNewFollowUp = () => {
        setIssueId(issue.id);
        setIsDetailModalVisible(true);
        setIsFollowUpCreateModal(true);
    };

    const handleDuplicate = () => {
        setIssueId(issue.id);
        setIsDuplicateModalVisible(true);
    };

    const handleDelete = async () => {
        setIsSending(true);

        const response = await deleteIssue(issue.id);

        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        fetchIssues();
    };

    const handleReopen = async () => {
        setIsSending(true);

        const response = await reopenIssue(issue.id);

        await sleep(1000);

        setIsSending(false);

        app.notification.open(response);

        fetchIssues();
    };

    const shareIssue = async () => {
        setIsSending(true);

        const response = await shareIssueWithResident(issue.id, {
            share_with_resident: !issue.share_with_resident
        });

        await sleep(1000);

        setIsSending(false);

        if ('status' in response)
            return;

        fetchIssues();

        app.notification.open(response);
    };

    if (!Authorization.hasAccess(FEATURE['ISSUE::WRITE']))
        return (
            <Button
                onClick={handleDetail}
                loading={isSending}
            >
                Detalhes
            </Button>
        );

    const items = makeItems(
        issue,
        app,
        handleReopen,
        handleDuplicate,
        handleEdit,
        shareIssue,
        handleDelete,
        handleNewFollowUp,
    );

    return (
        <Dropdown.Button
            trigger={['click']}
            menu={{ items, disabled: isSending }}
            onClick={handleDetail}
            loading={isSending}
        >
            Detalhes
        </Dropdown.Button>
    );
};

export const Author = (record: Issue.With<| 'user' | 'resident'>) => {
    return record.user !== null
        ? record.user.name
        : record.resident !== null
            ? `${record.resident.name} (${record.resident.apartment.tower.name} - ${record.resident.apartment.name})`
            : 'Morador não autenticado';
};

const wl = makeWhiteLabel();

export const Columns: TableColumnsType<Issue.With<
    | 'user'
    | 'user_who_resolved'
    | 'user_responsible'
    | 'supplier'
    | 'issueType'
    | 'client'>> = [
        {
            title: 'ID',
            dataIndex: 'id',
            render: (value) => `#${value}`,
            width: 70
        },

        {
            title: 'Condomínio',
            dataIndex: ['client', 'name'],
            key: 'clientName'
        },

        {
            title: 'Status',
            dataIndex: 'status',
            render: (status, issue) => {
                const derivedStatus = getIssueDerivedStatus(status, issue.deadline);
                const props = getIssueDerivedStatusTagProps(derivedStatus);

                return <Tag {...props} />;
            },
            width: 120
        },

        {
            title: 'Aberto em',
            dataIndex: 'creation_date',
            render: (value) => dayjs(value).format('DD/MM/YYYY'),
            width: 110
        },

        {
            title: 'Prazo',
            dataIndex: 'deadline',
            render: (value) => value !== null
                ? dayjs(value).format('DD/MM/YYYY')
                : '-',
            width: 110
        },

        {
            title: 'Responsável',
            dataIndex: 'user_responsible_id',
            render: (_, { user_responsible }) => {
                if (!user_responsible)
                    return '-';

                const [firstName, lastName] = user_responsible.name.split(' ');

                return (
                    <Tooltip title={user_responsible.name} placement="top">
                        <Space>
                            <Avatar src={user_responsible?.picture} style={{ backgroundColor: wl.colorPrimary }}>
                                {firstName[0]}
                                {lastName ? lastName[0] : ''}
                            </Avatar>

                            {firstName}
                        </Space>
                    </Tooltip>
                );
            },
        },

        {
            title: 'Categoria',
            dataIndex: 'issue_type_id',
            render: (_, record) => record.issue_type.name
        },
        {
            title: 'Assunto',
            dataIndex: 'subject'
        },
        {
            render: (_, issue) => <Actions issue={issue} />,
        },
    ];