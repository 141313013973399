import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Button, Form, Input, message } from 'antd';

import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';

import Authentication from '../../lib/Authentication';
import Request from '../../lib/Request';

const wl = makeWhiteLabel();
export default class Login extends React.Component<RouteComponentProps> {
    state = {
        loading: false
    };

    render() {
        return (
            <div className="screen-container">
                <div className="form-container">
                    <Form name="login" layout="vertical" onFinish={this.handleSubmit}>
                        <div style={{ textAlign: 'center', padding: 20 }}>
                            <img src={wl.logo} alt="logo" style={{ width: '80%' }} />
                        </div>

                        <Form.Item name="email" rules={[{ required: true, type: 'email', message: 'Por favor, digite seu e-mail' }]}>
                            <Input type="email" prefix={<UserOutlined className="site-form-item-icon" />} placeholder={'Insira o seu e-mail'} />
                        </Form.Item>

                        <Form.Item name="password" rules={[{ required: true, message: 'Por favor, digite sua senha' }]}>
                            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder={'Insira a sua senha'} />
                        </Form.Item>

                        <hr />
                        <div className="flex align-center space-between">
                            <Button loading={this.state.loading} type="primary" htmlType="submit">{'LOGIN'}</Button>
                            <a href="/recuperar-senha" className="fs-20">Esqueceu sua senha?</a>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }

    handleSubmit = async (values: any) => {
        this.setState({ loading: true });

        const res = await Request.post('/auth/login', values);

        if (res.status === 401) {
            this.setState({ loading: false });
            return message.warning('Credenciais inválidas. Verifique seu usuário e/ou senha.');
        }

        if (res.status === 403) {
            this.setState({ loading: false });
            return message.warning('Entre em contato com o suporte');
        }

        Authentication.login(res.jwt, res.user);

        this.setState({ loading: false });
        this.props.history.push('/');
    };
}
