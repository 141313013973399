import React, { Fragment, useMemo } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { Button, Card, Col, Row, Space, Typography } from 'antd';

import { PieChartOutlined } from '@ant-design/icons';
import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { CreateIssueModal } from 'components/Issue/CreateIssueModal';
import { DetailIssueModal } from 'components/Issue/DetailIssueModal';
import { DuplicateIssueModal } from 'components/Issue/DuplicateIssueModal';
import { EditIssueModal } from 'components/Issue/EditIssueModal';
import ReportIssueModal from 'components/Issue/ReportIssueModal';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { IssueContextProvider } from 'lib/providers/IssueContextProvider';
import { Show } from 'lib/Show';

import { Columns } from './Columns';
import { Filters } from './Filters';

export function Issues({ location }: RouteComponentProps) {
    const history = useHistory();
    const isOverviewModule = location.pathname.includes('/overview');

    const filteredColumns = useMemo(() => (
        !isOverviewModule
            ? Columns.filter(column => column.key !== 'clientName')
            : Columns
    ), [isOverviewModule]);

    return (
        <IssueContextProvider isOverviewModule={isOverviewModule}>
            {({
                issue,
                issuesFiltered,
                isLoading,
                isCreateModalVisible,
                setIsCreateModalVisible,
                isEditModalVisible,
                isDuplicateModalVisible,
                isDetailModalVisible,
                setIsReportIssueModal,
                isReportIssueModal
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <span style={{ display: 'flex', gap: 8, alignItems: 'center', margin: '24px 0 12px' }}>
                                <Typography.Title style={{ margin: 0 }} level={3}>Chamados</Typography.Title>
                            </span>
                            <Space>
                                <Show when={!isOverviewModule}>
                                    <Button
                                        type="default"
                                        onClick={() => setIsReportIssueModal(true)}
                                    >
                                        Gerar relatório
                                    </Button>
                                </Show>
                                <Show when={isOverviewModule}>
                                    <Button
                                        icon={<PieChartOutlined />}
                                        type="default"
                                        onClick={() => history.push('/overview/issue/stats')}
                                    >
                                        Estatísticas
                                    </Button>
                                </Show>
                                <Show when={Authorization.hasAccess(FEATURE['ISSUE::WRITE'])}>
                                    <Button
                                        type="primary"
                                        onClick={() => setIsCreateModalVisible(true)}
                                    >
                                        Cadastrar
                                    </Button>
                                </Show>
                            </Space>
                        </Row>
                        <Row>
                            <Col style={{ paddingRight: 0 }} span={24}>
                                <Card>
                                    <Filters isOverviewModule={isOverviewModule} />

                                    <List
                                        columns={filteredColumns}
                                        data={issuesFiltered}
                                        loading={isLoading}
                                    />
                                </Card>
                            </Col>
                        </Row>

                    </Content>

                    <Show when={issue !== null && isDetailModalVisible}>
                        <DetailIssueModal />
                    </Show>

                    <Show when={isCreateModalVisible}>
                        <CreateIssueModal />
                    </Show>

                    <Show when={isEditModalVisible}>
                        <EditIssueModal />
                    </Show>

                    <Show when={isDuplicateModalVisible}>
                        <DuplicateIssueModal />
                    </Show>

                    <Show when={isReportIssueModal}>
                        <ReportIssueModal />
                    </Show>
                </Fragment>
            )}
        </IssueContextProvider>
    );
}
