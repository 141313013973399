import { matchPath } from 'react-router-dom';

import { AuthRoute, AuthRoutes, NonAuthRoute, NonAuthRoutes } from '../../components/_Common/_Routes/Routes';
import { PROFILE } from './Authorization.helper';

export const ADMIN_USER_ROUTES = [
    AuthRoutes.overview,
    AuthRoutes.overviewIssue,
    AuthRoutes.overviewMaintenance,
    AuthRoutes.dashboard,
    AuthRoutes.apartment,
    AuthRoutes.apartmentView,
    AuthRoutes.resident,
    AuthRoutes.vehicle,
    AuthRoutes.issue,
    AuthRoutes.maintenance,
    AuthRoutes.notice,
    AuthRoutes.ambient,
    AuthRoutes.document,
    AuthRoutes.reserve,
    AuthRoutes.chatbot,
    AuthRoutes.overviewSupplier,
    AuthRoutes.overviewDocument,
    AuthRoutes.consumption,
    AuthRoutes.construction,
    AuthRoutes.overviewUser,
    AuthRoutes.updatePassword,
    AuthRoutes.general_meeting,
    AuthRoutes.general_meeting_report,
    AuthRoutes.pending_payment,
    AuthRoutes.account,
    AuthRoutes.setup,
    AuthRoutes.overviewIssueStats,
    AuthRoutes.overviewSchedule,
    AuthRoutes.schedule,
    AuthRoutes.overviewReport,
    AuthRoutes.overviewPayment,
    AuthRoutes.overviewConstruction,
] as const;

export const CONCIERGE_USER_ROUTES = [
    AuthRoutes.concierge,
    AuthRoutes.reserve,
    AuthRoutes.updatePassword,
    AuthRoutes.resident,
    AuthRoutes.vehicle,
    AuthRoutes.pending_payment
] as const;

export const MEASUREMENT_USER_ROUTES = [
    AuthRoutes.consumption,
    AuthRoutes.updatePassword,
    AuthRoutes.pending_payment
] as const;

export const CARETAKER_USER_ROUTES = [
    AuthRoutes.dashboard,
    AuthRoutes.apartment,
    AuthRoutes.apartmentView,
    AuthRoutes.issue,
    AuthRoutes.maintenance,
    AuthRoutes.notice,
    AuthRoutes.reserve,
    AuthRoutes.consumption,
    AuthRoutes.construction,
    AuthRoutes.updatePassword,
    AuthRoutes.pending_payment,
    AuthRoutes.schedule,
] as const;

export const MANAGER_USER_ROUTES = [
    AuthRoutes.overview,
    AuthRoutes.overviewIssue,
    AuthRoutes.overviewMaintenance,
    AuthRoutes.overviewIssueStats,
    AuthRoutes.dashboard,
    AuthRoutes.apartment,
    AuthRoutes.apartmentView,
    AuthRoutes.resident,
    AuthRoutes.vehicle,
    AuthRoutes.issue,
    AuthRoutes.maintenance,
    AuthRoutes.document,
    AuthRoutes.notice,
    AuthRoutes.ambient,
    AuthRoutes.reserve,
    AuthRoutes.chatbot,
    AuthRoutes.overviewSupplier,
    AuthRoutes.overviewDocument,
    AuthRoutes.consumption,
    AuthRoutes.construction,
    AuthRoutes.updatePassword,
    AuthRoutes.general_meeting,
    AuthRoutes.pending_payment,
    AuthRoutes.overviewSchedule,
    AuthRoutes.schedule,
    AuthRoutes.overviewReport,
    AuthRoutes.overviewPayment,
    AuthRoutes.overviewConstruction,
] as const;

export const ISSUE_USER_ROUTES = [
    AuthRoutes.issue,
    AuthRoutes.updatePassword,
    AuthRoutes.pending_payment,
    AuthRoutes.schedule,
] as const;

export const ISSUE_MAINTENANCE_USER_ROUTES = [
    AuthRoutes.issue,
    AuthRoutes.maintenance,
    AuthRoutes.overview,
    AuthRoutes.overviewIssue,
    AuthRoutes.overviewMaintenance,
    AuthRoutes.dashboard,
    AuthRoutes.updatePassword,
    AuthRoutes.pending_payment,
    AuthRoutes.overviewSchedule,
    AuthRoutes.schedule,
] as const;

export const ADVISOR_USER_ROUTES = [
    AuthRoutes.issue,
    AuthRoutes.maintenance,
    AuthRoutes.overview,
    AuthRoutes.overviewIssue,
    AuthRoutes.overviewMaintenance,
    AuthRoutes.dashboard,
    AuthRoutes.updatePassword,
    AuthRoutes.pending_payment,
    AuthRoutes.schedule,
    AuthRoutes.overviewSchedule,
    AuthRoutes.construction,
    AuthRoutes.overviewConstruction,
    AuthRoutes.overviewReport,
] as const;

export function isMatchedPath(pathname: string, path: string | readonly string[]): boolean {
    const match = matchPath(pathname, { path, exact: true });

    const isMatched = match !== null;

    return isMatched;
}

export function isValidNonAuthRoute(route: any): route is NonAuthRoute {
    const isValid = Object
        .values(NonAuthRoutes)
        .some(nonAuthRoute => isMatchedPath(route, nonAuthRoute));

    return isValid;
}

/** @see https://v5.reactrouter.com/web/api/matchPath */
export function isValidAuthRoute(route: any): route is AuthRoute {
    const isValid = Object
        .values(AuthRoutes)
        .some(authRoute => isMatchedPath(route, authRoute));

    return isValid;
}

/** Abstraction layer to render or not render the main layout along the route component. */
export function hasMainLayout(route: AuthRoute): boolean {
    // Runtime check
    if (!isValidAuthRoute(route))
        throw new Error(`Invalid authenticated route was provided. The route provided was: ${route}`);

    const ROUTES_WITHOUT_MAIN_LAYOUT: AuthRoute[] = [
        AuthRoutes.updatePassword,
        AuthRoutes.overview,
        AuthRoutes.pending_payment,
        AuthRoutes.account,
        AuthRoutes.setup,
        AuthRoutes.overviewIssue,
        AuthRoutes.overviewMaintenance,
        AuthRoutes.overviewIssueStats,
        AuthRoutes.overviewSchedule,
        AuthRoutes.overviewReport,
        AuthRoutes.overviewPayment,
        AuthRoutes.overviewConstruction,
        AuthRoutes.overviewUser,
        AuthRoutes.overviewSupplier,
        AuthRoutes.overviewDocument,
    ];

    const hasMainLayout = !ROUTES_WITHOUT_MAIN_LAYOUT.some(path => isMatchedPath(route, path));

    return hasMainLayout;
}

/** Abstraction layer to render or not render the secondary layout along the route component. */
export function hasSecondaryLayout(route: AuthRoute): boolean {
    // Runtime check
    if (!isValidAuthRoute(route))
        throw new Error(`Invalid authenticated route was provided. The route provided was: ${route}`);

    const ROUTES_WITH_SECONDARY_LAYOUT: AuthRoute[] = [
        AuthRoutes.overview,
        AuthRoutes.overviewIssue,
        AuthRoutes.overviewMaintenance,
        AuthRoutes.overviewIssueStats,
        AuthRoutes.overviewSchedule,
        AuthRoutes.overviewReport,
        AuthRoutes.overviewPayment,
        AuthRoutes.overviewConstruction,
        AuthRoutes.overviewUser,
        AuthRoutes.overviewSupplier,
        AuthRoutes.overviewDocument,
    ];

    const hasSecondaryLayout = ROUTES_WITH_SECONDARY_LAYOUT.some(path => isMatchedPath(route, path));

    return hasSecondaryLayout;
}

export function hasAuthRouteAccess(userProfile: User.Model['profile'], route: AuthRoute): boolean {
    const profiles: Record<User.Model['profile'], boolean> = {
        [PROFILE.ADMIN]: isMatchedPath(route, ADMIN_USER_ROUTES),
        [PROFILE.CONCIERGE]: isMatchedPath(route, CONCIERGE_USER_ROUTES),
        [PROFILE.CARETAKER]: isMatchedPath(route, CARETAKER_USER_ROUTES),
        [PROFILE.MEASUREMENT]: isMatchedPath(route, MEASUREMENT_USER_ROUTES),
        [PROFILE.MANAGER]: isMatchedPath(route, MANAGER_USER_ROUTES),
        [PROFILE.ISSUE]: isMatchedPath(route, ISSUE_USER_ROUTES),
        [PROFILE.ISSUE_MAINTENANCE]: isMatchedPath(route, ISSUE_MAINTENANCE_USER_ROUTES),
        [PROFILE.ADVISOR]: isMatchedPath(route, ADVISOR_USER_ROUTES),
    } as const;

    const hasAccess = profiles[userProfile];

    return hasAccess;
}

/** Abstraction layer */
export function isHomepageRedirectNeeded(pathname: string) {
    if (pathname === '/')
        return true;

    return false;
}

export function getHomepageRoute(userProfile: User.Model['profile']): AuthRoute {
    const profiles: Record<User.Model['profile'], AuthRoute> = {
        [PROFILE.ADMIN]: AuthRoutes.overview,
        [PROFILE.CONCIERGE]: AuthRoutes.concierge,
        [PROFILE.CARETAKER]: AuthRoutes.dashboard,
        [PROFILE.MEASUREMENT]: AuthRoutes.consumption,
        [PROFILE.MANAGER]: AuthRoutes.overview,
        [PROFILE.ISSUE]: AuthRoutes.issue,
        [PROFILE.ISSUE_MAINTENANCE]: AuthRoutes.overview,
        [PROFILE.ADVISOR]: AuthRoutes.overview,
    } as const;

    const route = profiles[userProfile];

    return route;
}
