import React, { Fragment, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Button, Card, Col, Row, Space, Typography } from 'antd';

import Content from 'components/_Common/_Layout/Content';
import List from 'components/_Common/List';
import { CreateMaintenanceModal } from 'components/Maintenance/CreateMaintenanceModal';
import { DetailMaintenanceModal } from 'components/Maintenance/DetailMaintenanceModal';
import { EditMaintenanceModal } from 'components/Maintenance/EditMaintenanceModal';
import { FinishMaintenanceModal } from 'components/Maintenance/FinishMaintenanceModal';
import ReportMaintenanceModal from 'components/Maintenance/ReportMaintenanceModal';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { MaintenanceContextProvider } from 'lib/providers/MaintenanceContextProvider';
import { Show } from 'lib/Show';

import { Columns } from './Columns';
import { Filters } from './Filters';

export function Maintenances({ location }: RouteComponentProps) {
    const isOverviewModule = location.pathname.includes('/overview');

    const filteredColumns = useMemo(() => (
        !isOverviewModule
            ? Columns.filter(column => column.key !== 'clientName')
            : Columns
    ), [isOverviewModule]);

    return (
        <MaintenanceContextProvider isOverviewModule={isOverviewModule}>
            {({
                maintenance,
                isLoading,
                maintenancesFiltered,
                isCreateModalVisible,
                isEditModalVisible,
                isFinishMaintenanceModal,
                isDetailModalVisible,
                isReportMaintenanceModal,
                setIsReportMaintenanceModal,
                setIsCreateModalVisible,
            }) => (
                <Fragment>
                    <Content>
                        <Row justify="space-between" align="middle">
                            <span style={{ display: 'flex', gap: 8, alignItems: 'center', margin: '24px 0 12px' }}>
                                <Typography.Title style={{ margin: 0 }} level={3}>Manutenções</Typography.Title>
                            </span>
                            <Space>
                                <Show when={!isOverviewModule}>
                                    <Button
                                        type="default"
                                        onClick={() => setIsReportMaintenanceModal(true)}
                                    >
                                        Gerar relatório
                                    </Button>
                                </Show>
                                <Show when={Authorization.hasAccess(FEATURE['MAINTENANCE::WRITE'])}>
                                    <Button
                                        type="primary"
                                        onClick={() => setIsCreateModalVisible(true)}
                                    >
                                        Cadastrar
                                    </Button>
                                </Show>
                            </Space>
                        </Row>
                        <Row>
                            <Col style={{ paddingRight: 0 }} span={24}>
                                <Card>
                                    <Filters isOverviewModule={isOverviewModule} />

                                    <List
                                        columns={filteredColumns}
                                        data={maintenancesFiltered}
                                        loading={isLoading}
                                    />
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                    <Show when={isCreateModalVisible}>
                        <CreateMaintenanceModal />
                    </Show>
                    <Show when={maintenance !== null && isEditModalVisible}>
                        <EditMaintenanceModal />
                    </Show>
                    <Show when={maintenance !== null && isFinishMaintenanceModal}>
                        <FinishMaintenanceModal />
                    </Show>
                    <Show when={maintenance !== null && isDetailModalVisible}>
                        <DetailMaintenanceModal />
                    </Show>
                    <Show when={isReportMaintenanceModal}>
                        <ReportMaintenanceModal />
                    </Show>
                </Fragment>
            )}
        </MaintenanceContextProvider>
    );
}
