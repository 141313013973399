import React, { Fragment, useState } from 'react';

import { Avatar, Button, Card, Checkbox, Col, Collapse, Divider, Flex, Modal, notification, Popconfirm, Row, Space, Tag, Timeline, Typography } from 'antd';
import { CollapseProps } from 'antd/lib/collapse';

import { CheckOutlined, DeleteOutlined, DownOutlined, EyeTwoTone, MailOutlined, PlusOutlined, UpOutlined, UserOutlined } from '@ant-design/icons';
import { FilesGalery } from 'components/_Common/FilesGalery';
import dayjs from 'dayjs';
import { Authorization, FEATURE } from 'lib/helpers/Authorization.helper';
import { getIssueDerivedStatus, getIssueDerivedStatusTagProps } from 'lib/helpers/Issue.helper';
import { useIssue } from 'lib/providers/IssueContextProvider';
import { Show } from 'lib/Show';
import { sleep } from 'lib/Sleep';
import { Author } from 'pages/issue/Columns';
import { deleteIssueFollowUp, shareIssueToSupplier } from 'services/Issue.service';

import FinishIssueFormModal from './FinishIssueFormModal';
import FollowUpCreateModal from './FollowUpCreateModal';
import ViewTaskModal from './ViewTaskModal';

export function DetailIssueModal() {
    const [isSendingEmail, setIsSendingEmail] = useState<boolean>(false);

    const {
        issue,
        setIsDetailModalVisible,
        setIsFollowUpCreateModal,
        isFollowUpCreateModal,
        isFinishIssueModal,
        setIsFinishIssueModal,
        setIssueId,
        fetchIssues,
        setTask,
        setViewTaskModal,
        viewTaskModal
    } = useIssue();

    if (!issue)
        throw new Error('Value of the `issue` property is unknown');

    const close = () => {
        setIsDetailModalVisible(false);
        setIssueId(null);
    };

    const sendEmailToSupplier = async () => {
        setIsSendingEmail(true);

        const response = await shareIssueToSupplier(issue.id);

        await sleep(1000);

        setIsSendingEmail(false);

        if (!response.success)
            return;

        notification.success({
            message: 'Sucesso',
            description: 'E-mail enviado com sucesso.'
        });
    };

    const deleteFollowUp = async (id: Issue.IssueFollowUp['id']) => {
        const response = await deleteIssueFollowUp(id);
        if (!response.success)
            return;

        await fetchIssues();
        notification.success({ message: 'Sucesso', description: response.message });
    };

    const followUpTimeline = () => {
        return issue.issue_follow_up.map(fup => ({
            dot: <Avatar
                size={38}
                src={fup.user.picture}
                icon={<UserOutlined />} />,
            children:
                <Typography.Paragraph>
                    <Typography.Paragraph style={{ fontWeight: 'bold', marginBottom: 1 }}>
                        {fup.user.name} em {dayjs(fup.created_at).format('DD/MM/YYYY HH:mm')}
                        <Show when={Authorization.hasAccess(FEATURE['ISSUE::WRITE']) && issue.status !== 'closed'}>
                            <Divider type='vertical' />
                            <Popconfirm title={'Deseja remover?'}
                                placement="top"
                                onConfirm={() => deleteFollowUp(fup.id)}
                                cancelText={'Cancelar'}
                                okText={'Deletar'}
                                okType={'danger'}>
                                <Button type='text' size='small'><DeleteOutlined /></Button>
                            </Popconfirm>
                        </Show>
                    </Typography.Paragraph>

                    <Show when={!fup.is_internal}>
                        <Typography.Paragraph style={{ fontSize: 10, marginBottom: 1 }}>
                            Comentário público
                        </Typography.Paragraph>
                    </Show>

                    <Typography.Paragraph>
                        <div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: fup.description }}></div>
                    </Typography.Paragraph>

                    <Show when={fup.files.length !== 0}>
                        <Collapse
                            accordion
                            ghost
                            size="small"
                            expandIcon={({ isActive }) => isActive ? <UpOutlined /> : <DownOutlined />}
                            items={[{ key: fup.id, label: 'Arquivos', children: <FilesGalery files={fup.files} /> }]}
                        />
                    </Show >
                </Typography.Paragraph>
        }));
    };

    const derivedStatus = getIssueDerivedStatus(issue.status, issue.deadline);
    const props = getIssueDerivedStatusTagProps(derivedStatus);
    const handleViewTaskModal = (option: Issue.Tasks | undefined) => {
        if (!option)
            throw new Error('Task not found!');

        setTask(option);
        setViewTaskModal(true);
    };

    const optionTasks = (checklist: Issue.Checklist) => {
        const options = checklist.tasks.map((task) => (
            { label: task.description, value: task.description, type: task.type, id: task.id, files: task.files }
        ));

        return (
            <Flex vertical>
                {options.map((option) => (
                    <>
                        <Checkbox
                            key={option.value}
                            value={option.value}
                            checked={option.type !== 'pending'}
                            disabled
                        >
                            <Space size={7} >
                                <span> {option.label} </span>
                                <Show when={option.type !== 'pending'}>
                                    <Typography
                                        style={{ color: '#1890ff', cursor: 'pointer' }}
                                        onClick={() => handleViewTaskModal(checklist.tasks.find((task) => task.id === option.id))}
                                    >
                                        <EyeTwoTone /> Visualizar
                                    </Typography>
                                </Show>
                            </Space>
                        </Checkbox>
                    </>
                ))}
            </Flex>
        );
    };

    const checklistsAndTasks: CollapseProps['items'] = issue.issue_checklist.map((checklist) => ({
        key: checklist.name,
        label: checklist.name,
        children: optionTasks(checklist),
    }));

    const isChecklist = issue.type === 'checklist';

    const title = (
        <Fragment>
            <Tag {...props} />
            Chamado #{issue.id}
        </Fragment>
    );

    return (
        <Modal
            centered
            title={title}
            onCancel={close}
            footer={null}
            width={1000}
            open
        >
            <Row justify={'space-between'}>
                <Col xs={24} sm={24} md={11} lg={11}>
                    <Typography.Paragraph style={{ marginBottom: 8 }}><strong>Categoria do chamado: </strong>{issue.issue_type.name}</Typography.Paragraph>
                    <Typography.Paragraph style={{ marginBottom: 8 }}><strong>Assunto: </strong>{issue.subject}</Typography.Paragraph>

                    <Show when={issue.user_responsible !== null}>
                        <Typography.Paragraph style={{ marginBottom: 8 }}>
                            <strong>Responsável:</strong>
                            {' '}
                            {issue.user_responsible?.name}
                        </Typography.Paragraph>
                    </Show>
                    <Show when={issue.supplier_id !== null}>
                        <Typography.Paragraph style={{ marginBottom: 8 }}><strong>Fornecedor: </strong>{issue.supplier?.name}</Typography.Paragraph>
                        <Show when={issue.status !== 'closed'}>
                            <Button
                                loading={isSendingEmail}
                                onClick={sendEmailToSupplier}
                                icon={<MailOutlined />}
                                type='dashed'>Enviar email para o fornecedor</Button>
                        </Show>
                    </Show>
                    <Typography.Paragraph style={{ marginBottom: 8 }}><strong>Aberto por: </strong>{Author(issue)} em {dayjs(issue.creation_date).format('DD/MM/YYYY')}</Typography.Paragraph>

                    <Show when={!isChecklist}>
                        <Typography.Paragraph 
                            style={{ marginBottom: 8, whiteSpace: 'pre-line' }}
                            ellipsis={{ rows: 2, expandable: true, symbol: 'ler mais' }}
                        >
                            <strong>Descrição: </strong>
                            {issue.description}
                        </Typography.Paragraph>
                    </Show>

                    <Show when={isChecklist}>
                        <Typography.Paragraph
                            style={{ marginBottom: 8 }}
                        >
                            <strong>Tarefas: </strong>
                            <Collapse accordion items={checklistsAndTasks} />
                        </Typography.Paragraph>
                    </Show>

                    <Show when={viewTaskModal}>
                        <ViewTaskModal />
                    </Show>

                    <Show when={issue.deadline !== null}>
                        <Typography.Paragraph style={{ marginBottom: 8 }}>
                            <strong>Prazo estimado para finalização:</strong>
                            {' '}
                            {dayjs(issue.deadline).format('DD/MM/YYYY')}
                        </Typography.Paragraph>
                    </Show>

                    <Show when={issue.status === 'closed'}>
                        <Typography.Paragraph style={{ marginBottom: 8 }}><strong>Finalizado por: </strong>{issue.user_who_resolved?.name} em {dayjs(issue.resolutionDate).format('DD/MM/YYYY')}</Typography.Paragraph>

                        <Show when={!isChecklist}>
                            <Typography.Paragraph style={{ marginBottom: 8 }} ellipsis={{ rows: 2, expandable: true, symbol: 'ler mais' }}><strong>Solução: </strong>{issue.finalization}</Typography.Paragraph>
                        </Show>
                    </Show>
                    <FilesGalery files={issue.issue_file} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                    <Card bordered={false} style={{ maxHeight: '30rem', overflowY: 'auto' }}>
                        <Row justify="center" align="middle" style={{ minHeight: '100%' }}>
                            <Col>
                                <Timeline items={followUpTimeline()} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Show when={Authorization.hasAccess(FEATURE['ISSUE::WRITE']) && issue.status !== 'closed'}>
                <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Button
                            onClick={() => setIsFinishIssueModal(true)}
                            type="primary"
                            icon={<CheckOutlined />}
                            block
                        >
                            Finalizar chamado
                        </Button>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Button
                            onClick={() => setIsFollowUpCreateModal(true)}
                            type="dashed"
                            icon={<PlusOutlined />}
                            block
                        >
                            Novo acompanhamento
                        </Button>
                    </Col>
                </Row>
            </Show>
            <Show when={isFinishIssueModal}>
                <FinishIssueFormModal />
            </Show>
            <Show when={isFollowUpCreateModal}>
                <FollowUpCreateModal />
            </Show>
        </Modal>
    );
}
