import React, { 
    createContext, 
    Dispatch, 
    ReactNode,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';

import { App } from 'antd';

import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { listAllVehicles } from 'services/Vehicle.service';
import { Vehicle } from 'types/Vehicle';

type Value = {
    isLoading: boolean,
    vehicles: Vehicle.With<'apartment'>[],
    setVehicles: Dispatch<SetStateAction<Value['vehicles']>>,
    vehicle: Vehicle.With<'apartment'> | null,
    vehicleId: Vehicle.Model['id'] | null,
    setVehicleId: Dispatch<SetStateAction<Value['vehicleId']>>,
    isCreateModalVisible: boolean,
    setIsCreateModalVisible: Dispatch<SetStateAction<Value['isCreateModalVisible']>>,
    isEditModalVisible: boolean,
    setIsEditModalVisible: Dispatch<SetStateAction<Value['isEditModalVisible']>>,
    fetchVehicles: () => Promise<void>,
};

type Props = { children: (value: Value) => ReactNode };

const VehicleContext = createContext<Value | null>(null);

/** @see https://www.youtube.com/watch?v=I7dwJxGuGYQ */
export function VehicleContextProvider({ children }: Props) {
    const [isLoading, setIsLoading] = useState(true);

    const [vehicles, setVehicles] = useState<Value['vehicles']>([]);
    const [vehicleId, setVehicleId] = useState<Value['vehicleId']>(null);

    const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const app = App.useApp();

    const fetchVehicles = async () => {
        setIsLoading(true);

        const response = await listAllVehicles({});

        setIsLoading(false);

        if (!response.success)
            return handleServiceError(app, response);

        setVehicles(response.vehicles);
    };

    const memoizedFetchVehicles = useCallback(fetchVehicles, [app]);

    useEffect(() => {
        memoizedFetchVehicles();
    }, [memoizedFetchVehicles]);

    const vehicle = useMemo(() => {
        if (!vehicleId)
            return null;

        const found = vehicles.find(vehicle => vehicle.id === vehicleId);

        if (!found)
            throw new Error(`Could not find a document with id ${vehicleId}`);

        return found;

    }, [vehicles, vehicleId]);

    const value: Value = { 
        isLoading, 
        vehicles,
        setVehicles,
        vehicle,
        vehicleId,
        setVehicleId,
        isCreateModalVisible,
        setIsCreateModalVisible,
        isEditModalVisible,
        setIsEditModalVisible,
        fetchVehicles,
    };

    return (
        <VehicleContext.Provider value={value}>
            {children(value)}
        </VehicleContext.Provider>
    );
}

export function useVehicle() {
    const context = useContext(VehicleContext);

    if (!context)
        throw new Error('Context is unknown. Perhaps the hook invocation is not inside a `VehicleContextProvider`.');

    return context;
}