import React, { useReducer, useState } from 'react';

import { Button, Col, DatePicker, Input, Row, Select, Tooltip } from 'antd';

import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import dayjs, { Dayjs } from 'dayjs';
import { isFilledField, makeFilterOption } from 'lib/helpers/Form.helper';
import { useConstruction } from 'lib/providers/ConstructionContextProvider';
import { Show } from 'lib/Show';
import { makeClientOptions } from 'pages/issue/Filters';
import type { OptionalField } from 'types/packages/antd';

type Props = {
    /** @default false */
    isOverviewModule?: boolean;
};

type Filter = {
    client: OptionalField<Construction.Model['client_id']>;
    title: OptionalField<Construction.Model['name']>;
    status: OptionalField<Construction.Status>;
    date: OptionalField<[Dayjs | null, Dayjs | null]> | null;
};

const reducer = (state: Filter, newState: Partial<Filter>) => ({ ...state, ...newState });

const FILTER_DEFAULT_VALUE: Filter = {
    client: undefined,
    title: undefined,
    status: undefined,
    date: [null, null]
};

const filterOption = makeFilterOption();

export function ConstructionsFilterBar({ isOverviewModule = false }: Props) {
    const [filter, setFilter] = useReducer(reducer, FILTER_DEFAULT_VALUE);
    const [isFiltering, setIsFiltering] = useState(false);
    const { constructions, setConstructions, fetchConstructions } = useConstruction();

    const handleClear = () => {
        setIsFiltering(false);
        setFilter(FILTER_DEFAULT_VALUE);
        fetchConstructions();
    };

    const handleFilter = () => {
        let filteredConstructions = constructions;

        const { client, title, status, date } = filter;

        if (isFilledField<Construction.Model['client_id']>(client)) 
            filteredConstructions = filteredConstructions.filter(construction => construction.client_id === client);

        if (isFilledField<Construction.Model['name']>(title))
            filteredConstructions = filteredConstructions.filter(construction => 
                construction.name.toLowerCase().includes(title.toLowerCase())
            );

        if (isFilledField<Construction.Status>(status))
            filteredConstructions = filteredConstructions.filter(construction => construction.status === status);

        if (date && Array.isArray(date) && date[0] && date[1]) {
            filteredConstructions = filteredConstructions.filter(construction => {
                const constructionDate = dayjs(construction.start_date);
        
                return construction.start_date !== null && constructionDate.isBetween(date[0], date[1], null, '[]');
            });
        }
        setIsFiltering(true);
        setConstructions(filteredConstructions);
    };

    const isActionButtonDisabled = Object.values(filter).every(value =>
        value === undefined || (Array.isArray(value) && value.every(v => v === null))
    );

    const parsedStatus = [
        { value: 'planning', label: 'Em planejamento' },
        { value: 'in_progress', label: 'Em andamento' },
        { value: 'paralyzed', label: 'Paralisada' },
        { value: 'late', label: 'Atrasada' },
        { value: 'finished', label: 'Finalizada' },
    ];

    return (
        <Row gutter={[8, 8]}>
            <Show when={isOverviewModule}>
                <Col md={6} xs={24}>
                    <Select
                        style={{ fontWeight: 'normal', width: '100%' }}
                        options={makeClientOptions()}
                        value={filter.client}
                        onChange={value => setFilter({ client: value })}
                        onClear={() => setFilter({ client: undefined })}
                        placeholder="Condomínio"
                        filterOption={filterOption}
                        disabled={isFiltering}
                        allowClear
                        showSearch
                    />
                </Col>
            </Show>

            <Col md={isOverviewModule ? 6 : 12} xs={24}>
                <Input
                    value={filter.title ?? undefined}
                    onChange={event => setFilter({ title: event.currentTarget.value })}
                    placeholder="Título"
                    disabled={isFiltering}
                />
            </Col>

            <Col md={4} xs={24}>
                <Select
                    style={{ fontWeight: 'normal', width: '100%' }}
                    options={parsedStatus}
                    value={filter.status}
                    onChange={value => setFilter({ status: value })}
                    onClear={() => setFilter({ status: undefined })}
                    placeholder="Status"
                    filterOption={filterOption}
                    disabled={isFiltering}
                    allowClear
                    showSearch
                />
            </Col>

            <Col md={4} xs={24}>
                <Tooltip title="O filtro será aplicado somente no período da data inicial. Caso ela não for preenchida, não irá aparecer.">
                    <DatePicker.RangePicker
                        style={{ width: '100%' }}
                        value={filter.date && filter.date[0] && filter.date[1] ? [filter.date[0], filter.date[1]] : null}
                        onChange={(value) => {
                            setFilter({ ...filter, date: value ? [dayjs(value[0]), dayjs(value[1])] : [null, null] });
                        }}
                        placeholder={['De', 'Até']}
                        format="DD/MM/YYYY"
                        disabled={isFiltering}
                    />
                </Tooltip>
            </Col>

            <Col md={4} xs={24}>
                <Button
                    block
                    type={isFiltering ? 'dashed' : 'primary'}
                    onClick={isFiltering ? handleClear : handleFilter}
                    icon={isFiltering ? <ClearOutlined /> : <FilterOutlined />}
                    title={isActionButtonDisabled ? 'Por favor, selecione ao menos um filtro.' : undefined}
                    disabled={isActionButtonDisabled}
                >
                    {isFiltering ? 'Limpar' : 'Filtrar'}
                </Button>
            </Col>
        </Row>
    );
}
