import { UploadFile } from 'antd';

import API from '../lib/API';
import {
    CreateMaintenance,
    CreateMaintenanceType,
    FindMaintenance,
    FinishMaintenance,
    ListMaintenance,
    ListMaintenanceType,
    ListPendingMaintenancesByClientManager,
    RemoveMaintenance,
    ReopenMaintenance,
    UpdateMaintenance,
    UploadMaintenanceFile
} from './contracts/Maintenance.contract';

const route = '/maintenance';

export function listMaintenance(clients: Client.Model['id'][] = []): Promise<ListMaintenance.Response> {
    const params = new URLSearchParams();

    clients.forEach(client => params.append('clients[]', client.toString()));

    return API.get(`${route}?${params.toString()}`);
};

export function listPendingMaintenancesByClientManagar(): Promise<ListPendingMaintenancesByClientManager.Response> {
    return API.get(`${route}/pending/client-manager`);
}

export function findMaintenance(id: Maintenance.Model['id']): Promise<FindMaintenance.Response> {
    return API.get(`${route}/${id}`);
};

export function createMaintenance(body: CreateMaintenance.Body): Promise<CreateMaintenance.Response> {
    return API.post(route, body);
};

export function updateMaintenance(id: Maintenance.Model['id'], body: UpdateMaintenance.Body): Promise<UpdateMaintenance.Response> {
    return API.put(`${route}/${id}?removeFiles=1`, body);
};

export function uploadMaintenanceFile(body: UploadMaintenanceFile.Body): Promise<UploadMaintenanceFile.Response> {
    return API.post('/default/maintenanceFiles', body);
};

export function finishMaintenance(id: Maintenance.Model['id'], body: FinishMaintenance.Body): Promise<FinishMaintenance.Response> {
    return API.post(`${route}/${id}/finish`, body);
};

export function uploadFileMaintenance(id: Maintenance.Model['id'], files: Array<UploadFile>) {
    const body: Array<Maintenance.FileToUpload> = files.map((file) => ({
        filename: file.name,
        url: file.response,
        maintenanceId: id
    }));

    API.post('/default/maintenanceFiles/multiple', body);
};

export function deleteMaintenance(id: Maintenance.Model['id']): Promise<RemoveMaintenance.Response> {
    return API.delete(`${route}/${id}`);
};

export function listMaintenaceType(): Promise<ListMaintenanceType.Response> {
    return API.get('/maintenance_type');
};

export function createMaintenaceType(body: CreateMaintenanceType.Body): Promise<CreateMaintenanceType.Response> {
    const route = '/maintenance_type';

    return API.post(route, body);
};

export function reopenMaintenance(id: Maintenance.Model['id']): Promise<ReopenMaintenance.Response> {//
    return API.put(`${route}/reopen/${id}`);
};