import React, { useEffect, useState } from 'react';

import {
    App,
    Button,
    Checkbox,
    Collapse,
    DatePicker,
    Divider,
    Flex,
    Form,
    Input,
    Modal,
    Popconfirm,
    Radio,
    Select,
    SelectProps,
    UploadFile
} from 'antd';
import { CollapseProps } from 'antd/lib/collapse';

import { DeleteOutlined, EditTwoTone, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DATETIME_SERVER_FORMAT } from 'internal-constants';
import Authentication from 'lib/Authentication';
import { DynamicSelect } from 'lib/DynamicSelect';
import { makeFilterOption } from 'lib/helpers/Form.helper';
import { handleServiceError } from 'lib/helpers/ServiceHelper';
import { useIssue } from 'lib/providers/IssueContextProvider';
import { Show } from 'lib/Show';
import { sleep } from 'lib/Sleep';
import { UploadField } from 'lib/UploadField';
import {
    createIssue,
    createIssueType,
    getIssueEligibleResponsibleUsers,
    listIssueType,
    uploadIssueFile
} from 'services/Issue.service';
import { createSupplier, listSuppliers } from 'services/Supplier.service';

import CreateChecklistModal from './CreateChecklistModal';
import EditChecklistModal from './EditChecklistModal';

type SelectOptions = NonNullable<SelectProps['options']>;

type Values = {
    client: Client.Model['id'],
    creation_date: dayjs.Dayjs,
    type: Issue.Type['id'],
    subject: Issue.Model['subject'],
    description: Issue.Model['description'],
    files: UploadFile[] | undefined,
    user_responsible_id: Issue.Model['user_responsible_id'] | undefined,
    supplier_id: Issue.Model['supplier_id'] | undefined,
    deadline: dayjs.Dayjs | null | undefined,
    issueType: Issue.EnumType,
    is_recurrent: 'recurrent' | 'single',
    recurrence?: number,
};

type Body = Parameters<typeof createIssue>['0'];
const filterOption = makeFilterOption();

export function DuplicateIssueModal() {
    const [types, setTypes] = useState<Issue.Type[]>([]);
    const [users, setUsers] = useState<User.Model[]>([]);
    const [suppliers, setSuppliers] = useState<Supplier.Model[]>([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [issueType, setIssueType] = useState<string>('text');
    const [recurrent, setRecurrent] = useState<string>('single');

    const [form] = Form.useForm<Values>();

    const {
        issue,
        setIsDuplicateModalVisible,
        fetchIssues,
        setChecklists,
        checklists,
        setChecklist,
        setEditChecklistModal,
        setCreateChecklistModal,
        editCheckListModal,
        createCheckListModal,
        setIssueId,
    } = useIssue();

    if (!issue)
        throw new Error('Value of the `document` property is unknown');

    const fetchData = async () => {
        setIsFetching(true);

        const promises = [
            getIssueEligibleResponsibleUsers(),
            listSuppliers(),
        ] as const;

        const [
            issueEligibleResponsibleUsersResponse,
            suppliersResponse,
        ] = await Promise.all(promises);

        // Only errors? Don't do anyting.
        if (!issueEligibleResponsibleUsersResponse.success && !suppliersResponse.success)
            return;

        // Error in `getIssueEligibleResponsibleUsers`, but success in `listSuppliers`? Let's show off what we got!
        if (!issueEligibleResponsibleUsersResponse.success && suppliersResponse.success) {
            setSuppliers(suppliersResponse.suppliers);
            setIsFetching(false);

            return suppliersResponse.suppliers;
        }

        // Error in `listSuppliers`, but success in `getIssueEligibleResponsibleUsers`? Let's show off what we got!
        if (issueEligibleResponsibleUsersResponse.success === true && !suppliersResponse.success) {
            setUsers(issueEligibleResponsibleUsersResponse.users);
            setIsFetching(false);

            return;
        }

        // No errors! All green, captain!
        if (issueEligibleResponsibleUsersResponse.success === true && suppliersResponse.success) {
            setUsers(issueEligibleResponsibleUsersResponse.users);
            setSuppliers(suppliersResponse.suppliers);
            setIsFetching(false);

            return suppliersResponse.suppliers;
        }

        return suppliers;
    };

    useEffect(() => {
        form.setFieldValue('user_responsible_id', undefined);
        form.setFieldValue('supplier_id', undefined);

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form]);

    const fetchTypes = async () => {
        setIsFetching(true);

        const response = await listIssueType();

        if (!response.success)
            return;

        setTypes(response.issue_types);
        setIsFetching(false);

        return response.issue_types;
    };

    useEffect(() => {
        fetchTypes();
    }, []);

    const app = App.useApp();

    const handleNewTypeOption = async (option: string) => {
        setIsFetching(true);

        if (option === '') {
            setIsFetching(false);
            return app.notification.error({ message: 'Atenção', description: 'Descreva a categoria que deseja inserir' });
        }

        const response = await createIssueType({ name: option });

        await sleep(1000);

        setIsFetching(false);

        app.notification.open(response);

        const fetchedTypes = await fetchTypes();

        const newType = fetchedTypes?.find(type => type.name === option);

        form.setFieldValue('type', newType?.id);
    };

    const handleNewSupplier = async (option: string) => {
        setIsFetching(true);

        // eslint-disable-next-line eqeqeq
        if (option == '') {
            setIsFetching(false);
            return app.notification.error({ message: 'Atenção', description: 'Descreva o fornecedor que deseja inserir' });
        }

        const clients = Authentication.getClients().map((client) => (client.id));

        const response = await createSupplier({ name: option, clientsToSync: clients });

        await sleep(1000);

        setIsFetching(false);

        app.notification.open(response);

        await fetchData();

        const suppliers = await fetchData();

        const newSupplier = suppliers?.find(supplier => supplier.name === option);

        form.setFieldValue('supplier_id', newSupplier?.id);
    };

    const onFinish = async (values: Values) => {
        if (values.issueType === 'checklist' && checklists.length === 0)
            return app.notification.error({ message: 'Atenção', description: 'Necessário adicionar ao menos um checklist para criar um chamado!' });

        setIsSending(true);

        const body: Body = {
            // TODO: Get this by back-end layer to improves security
            clientId: values.client,
            userId: Authentication.getUserId(),
            issue_checklists: checklists,
            type: values.issueType,
            creation_date: values.creation_date.toString(),
            issueTypeId: values.type,
            subject: values.subject,
            description: values.description,
            user_responsible_id: values.user_responsible_id ?? null,
            supplier_id: values.supplier_id ?? null,
            is_recurrent: recurrent === 'recurrent',
            recurrence: values.recurrence,
            deadline: values.deadline?.format(DATETIME_SERVER_FORMAT) ?? null,
        };

        const response = await createIssue(body);

        if ('success' in response) {
            setIsSending(false);
            return handleServiceError(app, response);
        }

        const parsedFiles = values.files?.map(file => ({
            filename: file.name,
            url: file.response ?? file.url,
            issueId: response.id,
        })) ?? [];

        const promises = parsedFiles.map(file => uploadIssueFile(file));

        const files = await Promise.all(promises);

        // Maybe we have more errors, but it'is not so important in this context
        const firstErrorFile = files.find((file): file is Service.ExceptionResponse => 'success' in file);

        if (firstErrorFile !== undefined)
            return handleServiceError(app, firstErrorFile);

        setIsSending(false);
        fetchIssues();
        setIsDuplicateModalVisible(false);
        setIssueId(null);
    };

    const removeChecklist = (checklistToRemove: Issue.Checklist) => {
        setChecklists(prevChecklists =>
            prevChecklists.filter(cl => {
                if (checklistToRemove.id === null)
                    return cl.name !== checklistToRemove.name;

                return cl.id !== checklistToRemove.id;
            })
        );
    };

    const handleEditChecklistModal = (checklist: Issue.Checklist) => {
        setChecklist(checklist);
        setEditChecklistModal(true);
    };

    const optionTasks = (checklist: Issue.Checklist) => {
        const options = checklist.tasks.map((task) => (
            { label: task.description, value: task.description }
        ));

        return (
            <Flex vertical>
                <Flex justify='end' gap={10}>
                    <EditTwoTone onClick={() => handleEditChecklistModal(checklist)} />

                    <Popconfirm
                        title="Deletar checklist"
                        description="Você tem certeza que deseja deletar o checklist?"
                        onConfirm={() => removeChecklist(checklist)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <DeleteOutlined style={{ color: 'red' }} />
                    </Popconfirm>
                </Flex>
                {options.map((option) => (
                    <Checkbox key={option.value} value={option.value}>
                        {option.label}
                    </Checkbox>
                ))}
            </Flex>
        );
    };

    const checklistsAndTasks: CollapseProps['items'] = checklists.map((checklist) => ({
        key: checklist.name,
        label: checklist.name,
        children: optionTasks(checklist),
    }));

    const parsedTypes: SelectOptions = types.map(({ id, name }) => ({ value: id, label: name }));

    const parsedUsers: SelectOptions = users.map(({ id, name }) => ({ value: id, label: name }));

    const parsedSuppliers: SelectOptions = suppliers.map(({ id, name }) => ({ value: id, label: name }));

    const issueTypes = [{ label: 'Texto', value: 'text' }, { label: 'Checklist', value: 'checklist' }];

    const isChecklist = issueType === 'checklist';

    const clients: SelectOptions = Authentication
        .getClients()
        .map(({ id, name }) => ({ value: id, label: name }));

    useEffect(() => {
        const deadline: Values['deadline'] = issue.deadline !== null
            ? dayjs().add(dayjs(issue.deadline).diff(issue.creation_date, 'days'), 'days')
            : null;

        const checklists = issue.issue_checklist.map((checklist) => ({
            id: checklist.id,
            name: checklist.name,
            tasks: checklist.tasks,
        }));

        setChecklists(checklists);
        setIssueType(issue.type);

        form.setFieldsValue({
            creation_date: dayjs(),
            type: issue.issueTypeId,
            user_responsible_id: issue.user_responsible_id,
            supplier_id: issue.supplier_id,
            subject: issue.subject,
            description: issue.description,
            files: [],
            is_recurrent: issue.is_recurrent ? 'recurrent' : 'single',
            recurrence: issue.recurrence,
            deadline,
            issueType: issue.type,
        });

        setRecurrent(issue.is_recurrent ? 'recurrent' : 'single');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [issue]);


    return (
        <Modal
            title="Duplicar chamado"
            confirmLoading={isSending}
            onOk={form.submit}
            okText="Salvar"
            onCancel={() => setIsDuplicateModalVisible(false)}
            cancelText="Cancelar"
            open
        >
            <Divider />

            <Form
                form={form}
                onFinish={onFinish}
                name="editIssue"
                layout="vertical"
                autoComplete="off"
            >
                <Form.Item<Values>
                    name="client"
                    label="Condomínio"
                    rules={[{ required: true, message: 'Por favor, selecione um condomínio.' }]}
                >
                    <Select
                        options={clients}
                        filterOption={filterOption}
                        showSearch
                    />
                </Form.Item>

                <Form.Item<Values>
                    name="creation_date"
                    label="Data de abertura"
                    rules={[{ required: true, message: 'Selecione uma data' }]}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                    />
                </Form.Item>

                <Form.Item<Values>
                    name="type"
                    label="Categoria"
                    rules={[{ required: true, message: 'Por favor, selecione a categoria do chamado.' }]}
                >
                    <DynamicSelect
                        options={parsedTypes}
                        dropdown={{ placeholder: 'Insira aqui uma nova categoria', onNewOption: handleNewTypeOption }}
                        loading={isFetching}
                    />
                </Form.Item>

                <Form.Item name="deadline" label="Prazo estimado para finalização">
                    <DatePicker
                        // Can not select days before today
                        disabledDate={current => current < dayjs().startOf('day')}
                        placeholder=""
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                    />
                </Form.Item>

                <Form.Item<Values>
                    name="user_responsible_id"
                    label="Usuário responsável"
                    tooltip='Usuários com o perfil "portaria" não são elegíveis como responsáveis.'
                >
                    <Select
                        options={parsedUsers}
                        loading={isFetching}
                        allowClear
                    />
                </Form.Item>

                <Form.Item<Values>
                    name="supplier_id"
                    label="Fornecedor"
                >
                    <DynamicSelect
                        options={parsedSuppliers}
                        loading={isFetching}
                        dropdown={{ placeholder: 'Insira aqui um novo fornecedor', onNewOption: handleNewSupplier }}
                    />
                </Form.Item>

                <Form.Item<Values>
                    name="subject"
                    label="Assunto"
                    rules={[{ required: true, message: 'Por favor, digite um assunto.' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="issueType"
                    label="Tipo de chamado:"
                    rules={[{ required: true, message: 'Por favor, selecione um tipo de chamado.' }]}
                >
                    <Radio.Group onChange={value => setIssueType(value.target.value)}>
                        {issueTypes.map(option => (
                            <Radio key={option.value} value={option.value}>
                                {option.label}
                            </Radio>
                        ))}
                    </Radio.Group>
                </Form.Item>

                <Show when={isChecklist}>
                    <Form.Item
                        name="checklist"
                        label='Checklists:'
                    >
                        <Show when={checklists.length !== 0 && isChecklist}>
                            <Form.Item name="checklists">
                                <Collapse accordion items={checklistsAndTasks} />
                            </Form.Item>
                        </Show>

                        <Button type="dashed" onClick={() => setCreateChecklistModal(true)} block icon={<PlusOutlined />}>
                            Checklist
                        </Button>
                    </Form.Item>
                </Show>

                <Show when={createCheckListModal}>
                    <CreateChecklistModal />
                </Show>

                <Show when={editCheckListModal}>
                    <EditChecklistModal />
                </Show>

                <Show when={!isChecklist}>
                    <Form.Item<Values>
                        name="description"
                        label="Descrição"
                        rules={[{ required: true, message: 'Por favor, digite uma descrição.' }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Show>

                <Form.Item<Values>
                    name="is_recurrent"
                    label="O chamado será realizado de forma recorrente?"
                    rules={[{ required: true, message: 'Selecione uma opção' }]}>
                    <Radio.Group onChange={value => setRecurrent(value.target.value)}>
                        <Radio value="recurrent" >Sim</Radio>
                        <Radio value="single" >Não</Radio>
                    </Radio.Group>
                </Form.Item>

                <Show when={recurrent === 'recurrent'}>
                    <Form.Item<Values>
                        name="recurrence"
                        label="Defina a recorrência (em dias)"
                        rules={[{ required: recurrent === 'recurrent', message: 'Defina a recorrência' }]}
                        help='A recorrência deve ser definida em dias, por exemplo, se o chamado deve ser realizado quinzenalmente, preencha o campo com o valor 15.'
                    >
                        <Input type='number' min={1} />
                    </Form.Item>
                </Show>

                <UploadField name='files' label='' type='picture' />
            </Form>
        </Modal>
    );
}
