import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { App as AntApp, ConfigProvider } from 'antd';
import { ConfigProviderProps } from 'antd/es/config-provider';
import ptBR from 'antd/es/locale/pt_BR';

import { AuthenticationErrorBoundary } from 'lib/AuthenticationErrorBoundary';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';
import { Account } from 'pages/account/Account.page';
import { Ambients } from 'pages/ambient/Ambients.page';
import { Chatbot } from 'pages/chatbot/Chatbot.page';
import { Client } from 'pages/client/Client.page';
import { CreateIssue } from 'pages/client/issue/CreateIssue.page';
import { Issues as ClientIssues } from 'pages/client/issue/list/Issues.page';
import { ResidentFirstAccess } from 'pages/client/ResidentFirstAccess.page';
import { ClientManagerPage } from 'pages/client_manager/ClientManagerPage.page';
import { Constructions } from 'pages/construction/Construction.page';
import { Consumptions } from 'pages/consumption/Consumption.page';
import { ExternalGeneralMeeting } from 'pages/general_meeting/[id]/external/ExternalGeneralMeeting';
import { GeneralMeetings } from 'pages/general_meeting/GeneralMeeting.page';
import { GeneralMeetingReport } from 'pages/general_meeting/GeneralMeetingReport.page';
import { Issues } from 'pages/issue/Issue.page';
import { Maintenances } from 'pages/maintenance/Maintenance.page';
import { RecoverResidentPassword } from 'pages/morador/recuperar-senha/RecoverResidentPassword.page';
import { Notices } from 'pages/notice/Notices.page';
import { Documents } from 'pages/overview/document/Documents.page';
import { Stats } from 'pages/overview/issue/stats/Stats.page';
import { Overview } from 'pages/overview/Overview.page';
import { ResetPasswordByEmail } from 'pages/password/ResetPasswordByEmail.page';
import { Payments } from 'pages/payment/Payments.page';
import { Report } from 'pages/report/Report.page';
import { Schedule } from 'pages/schedule/Schedule.page';
import { Setup } from 'pages/setup/Setup.page';
import { Vehicle } from 'pages/vehicle/Vehicle.page';

import { Suppliers } from '../pages/overview/supplier/Suppliers.page';
import { Users } from '../pages/overview/user/Users.page';
import { ProtectedRoutes } from './_Common/_Routes/ProtectedRoutes';
import { AuthRoutes, NonAuthRoutes } from './_Common/_Routes/Routes';
import { Forbidden } from './_Common/Forbidden';
import { NotFound } from './_Common/NotFound';
import ApartmentIndex from './Apartment/_Index';
import ApartmentView from './Apartment/View';
import BudgetIndex from './Budget/_Index';
import ConciergeIndex from './Concierge/_Index';
import DashboardIndex from './Dashboard/_Index';
import DeviceIndex from './Device/_Index';
import Login from './Login/Login';
import ReserveIndex from './Reserve/_Index';
import ResidentIndex from './Resident/_Index';
import { UpdatePassword } from './UpdatePassword';

const wl = makeWhiteLabel();
document.title = wl.name;

const link: any = document.querySelector('link[rel*=\'icon\']');

if (link) {
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = wl.favicon;
    document.getElementsByTagName('head')[0].appendChild(link);
}

const config: ConfigProviderProps = {
    locale: ptBR,
    theme: {
        components: {
            Layout: {
                siderBg: wl.siderBg,
                triggerBg: wl.triggerBg,
                headerBg: wl.siderBg
            },
            Menu: {
                darkItemBg: wl.darkItemBg,
                darkSubMenuItemBg: wl.darkItemBg,
                darkItemSelectedBg: wl.colorSecondary ?? wl.colorPrimary
            },
            Calendar: {
                itemActiveBg: wl.calendar?.itemActiveBg,
            }
        },
        token: {
            colorPrimary: wl.colorPrimary,
            fontFamily: 'Open Sans',
        },
    }
};

/** @see https://ant.design/components/app */
const App = () => {
    return (
        <AuthenticationErrorBoundary>
            <ConfigProvider {...config}>
                <AntApp>
                    <BrowserRouter>
                        <Switch>
                            <Route component={ResetPasswordByEmail} path={NonAuthRoutes.resetPassword} exact />
                            <Route component={Login} path={NonAuthRoutes.login} exact />
                            <Route component={RecoverResidentPassword} path={NonAuthRoutes.recoverResidentPassword} exact />
                            <Route component={ClientManagerPage} path={NonAuthRoutes.client_manager_page} exact />
                            <Route component={Forbidden} path={NonAuthRoutes.forbidden} exact />
                            <Route component={NotFound} path={NonAuthRoutes.notFound} exact />

                            <Route component={ExternalGeneralMeeting} path={NonAuthRoutes.externalGeneralMeetingView} exact />

                            <Route component={Client} path={NonAuthRoutes.client} exact />
                            <Route component={CreateIssue} path={NonAuthRoutes.clientIssue} exact />
                            <Route component={ClientIssues} path={NonAuthRoutes.clientIssueList} exact />
                            <Route component={ResidentFirstAccess} path={NonAuthRoutes.clientFirstAccess} exact />

                            <ProtectedRoutes>
                                <Switch>
                                    {/* Protected routes without the main layout */}
                                    <Route component={UpdatePassword} path={AuthRoutes.updatePassword} exact />

                                    {/* Homepages */}
                                    <Route component={DashboardIndex} path={AuthRoutes.dashboard} exact />
                                    <Route component={ConciergeIndex} path={AuthRoutes.concierge} exact />

                                    {/* Main layout */}
                                    <Route component={ApartmentIndex} path={AuthRoutes.apartment} exact />
                                    <Route component={ApartmentView} path={AuthRoutes.apartmentView} exact />
                                    <Route component={ResidentIndex} path={AuthRoutes.resident} exact />
                                    <Route component={Vehicle} path={AuthRoutes.vehicle} exact />
                                    <Route component={ReserveIndex} path={AuthRoutes.reserve} exact />
                                    <Route component={Issues} path={AuthRoutes.issue} exact />
                                    <Route component={DeviceIndex} path={AuthRoutes.device} exact />
                                    <Route component={Maintenances} path={AuthRoutes.maintenance} exact />
                                    <Route component={Notices} path={AuthRoutes.notice} exact />
                                    <Route component={Ambients} path={AuthRoutes.ambient} exact />
                                    <Route component={Chatbot} path={AuthRoutes.chatbot} exact />
                                    <Route component={BudgetIndex} path={AuthRoutes.budget} exact />
                                    <Route component={Constructions} path={AuthRoutes.construction} exact />
                                    <Route component={Documents} path={AuthRoutes.document} exact />
                                    <Route component={Consumptions} path={AuthRoutes.consumption} exact />
                                    <Route component={GeneralMeetings} path={AuthRoutes.general_meeting} exact />
                                    <Route component={GeneralMeetingReport} path={AuthRoutes.general_meeting_report} exact />
                                    <Route component={Account} path={AuthRoutes.account} exact />
                                    <Route component={Setup} path={AuthRoutes.setup} exact />
                                    <Route component={Schedule} path={AuthRoutes.schedule} exact />
                                    <Route component={Documents} path={AuthRoutes.document} exact />

                                    {/* Secondary layout */}
                                    <Route component={Overview} path={AuthRoutes.overview} exact />
                                    <Route component={Issues} path={AuthRoutes.overviewIssue} exact />
                                    <Route component={Constructions} path={AuthRoutes.overviewConstruction} exact />
                                    <Route component={Maintenances} path={AuthRoutes.overviewMaintenance} exact />
                                    <Route component={Stats} path={AuthRoutes.overviewIssueStats} exact />
                                    <Route component={Schedule} path={AuthRoutes.overviewSchedule} exact />
                                    <Route component={Report} path={AuthRoutes.overviewReport} exact />
                                    <Route component={Payments} path={AuthRoutes.overviewPayment} exact />
                                    <Route component={Users} path={AuthRoutes.overviewUser} exact />
                                    <Route component={Suppliers} path={AuthRoutes.overviewSupplier} exact />
                                    <Route component={Documents} path={AuthRoutes.overviewDocument} exact />
                                </Switch>
                            </ProtectedRoutes>
                        </Switch>
                    </BrowserRouter>
                </AntApp>

            </ConfigProvider >
        </AuthenticationErrorBoundary>
    );
};

export default App;
