import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Button, Modal, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';

import { AuthRoutes } from 'components/_Common/_Routes/Routes';
import List from 'components/_Common/List';
import dayjs, { Dayjs } from 'dayjs';
import Authentication from 'lib/Authentication';
import { formatBRL } from 'lib/helpers/BRL';

export function PendingInvoicesModal({ invoices }: { invoices: CobreFacil.Invoice[] }) {
    const [isOpen, setIsOpen] = useState(false);

    const { pathname } = useLocation();
    const profile = Authentication.getUserProfile();

    const columns: ColumnsType<CobreFacil.Invoice> = [
        {
            title: 'Status', key: 'due_date', dataIndex: 'due_date', render: (value) => dayjs().isAfter(dayjs(value))
                ? <Tag color='red'>Vencida</Tag>
                : <Tag color='blue'>Pendente</Tag>
        }, { title: 'Data de vencimento', key: 'due_date', dataIndex: 'due_date', render: (value) => dayjs(value).format('DD/MM/YYYY') },

        { title: 'Valor', key: 'price', dataIndex: 'price', render: (value) => formatBRL(value) },
        { title: '', key: 'url', dataIndex: 'url', render: (value) => <Button type='primary' href={value} target='_blank'>Pagar</Button> },
    ];

    function isBusinessDay(date: Dayjs) {
        const dayOfWeek = date.day();
        return dayOfWeek !== 0 && dayOfWeek !== 6;
    }

    function getValidDueDate(dueDate: Dayjs) {
        while (!isBusinessDay(dueDate)) {
            dueDate = dueDate.subtract(1, 'day');
        }
        return dueDate;
    }

    useEffect(() => {
        const currentDate = dayjs();

        if (invoices.length > 0) {
            const showModal = invoices.some(invoice => {
                const validDueDate = getValidDueDate(dayjs(invoice.due_date));
                
                if (currentDate.isSame(validDueDate, 'day') ||
                    // (!isBusinessDay(dayjs(invoice.due_date)) && currentDate.isSame(validDueDate.subtract(1, 'day'), 'day')) ||
                    currentDate.isAfter(validDueDate))
                    return true;
            });

            setIsOpen(showModal);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoices]);

    return (
        <Modal
            width={700}
            open={isOpen}
            footer={false}
            title="Mensalidades pendentes"
            onCancel={() => setIsOpen(false)}
        >
            <List
                columns={columns}
                data={invoices}
                showHeader={true}
                showPagination={false} />

        </Modal>
    );
}
