import React, { ReactNode, useState } from 'react';

import { Alert, AlertProps, FloatButton, Layout } from 'antd';

import { FileTextOutlined } from '@ant-design/icons';
import NoteModal from 'components/Note/Modal';
import dayjs from 'dayjs';
import { COPYRIGHT } from 'internal-constants';
import Authentication from 'lib/Authentication';
import { makeWhiteLabel } from 'lib/helpers/WhiteLabel.helper';

import { Header } from './Header';
import Sider from './Sider';

const wl = makeWhiteLabel();

type Props = { children: ReactNode };

export const expiredTrial = () => {
    const clientManager = Authentication.getClientManager();

    const totalDaysInUse = dayjs().diff(clientManager.created_at, 'days');
    const isTrial = ['trial', 'trial_sindigest'].includes(clientManager.contract);
    const isExpired = totalDaysInUse > 30;

    const supportNumber = clientManager.contract === 'trial'
        ? 'https://wa.me/555191639123'
        : 'https://wa.me/5551991503329';

    const alert: AlertProps = {
        type: 'info',
        showIcon: true,
        onClick: _ => window.open(supportNumber, '_blank'),
        style: { cursor: 'pointer' },
    };

    if (!isTrial)
        return null;

    if (isTrial && totalDaysInUse < 20){
        alert.message = `Seu período de testes está ativo, restam apenas ${30 - totalDaysInUse} dias. Clique aqui e entre em contato para saber mais sobre nossos planos de assinatura.`;
    }

    if (isTrial && (totalDaysInUse >= 20 && totalDaysInUse <= 30)) {
        alert.message = `Seu período de testes está próximo do fim, restam apenas ${30 - totalDaysInUse} dias. Clique aqui e entre em contato para evitar interrupções no serviço.`;
        alert.type = 'warning';
        
    }

    if (isTrial && isExpired) {
        alert.message = 'Seu período de testes expirou. Clique aqui e entre em contato para continuar utilizando nossos serviços.';
        alert.type = 'error';
    }

    return <Alert {...alert} />;
};

export function MainLayout({ children }: Props) {
    // This is assigned only when a re-render happens!
    // If the value needs to be in real time, see the link above
    // https://chakra-ui.com/docs/hooks/use-media-query
    const mobile = window.matchMedia('(max-width: 768px)');
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider />
            <Layout>
                <Header />
                {expiredTrial()}
                <Layout.Content
                    style={{
                        margin: mobile.matches ? '0px 10px' : '0px 16px',
                        padding: mobile.matches ? 'unset' : '10px 24px',
                        minHeight: 280
                    }}
                >
                    {children}
                </Layout.Content>

                <Layout.Footer style={{ textAlign: 'center' }}>
                    {COPYRIGHT}
                </Layout.Footer>
            </Layout>
            <NoteModal isOpen={isOpen} setIsOpen={setIsOpen} />
            <FloatButton
                type='primary'
                icon={<FileTextOutlined />}
                onClick={() => setIsOpen(true)}
                description="Notas"
                shape="circle"
                style={{
                    position: 'fixed',
                    bottom: '75px',
                    right: '20px',
                    width: '60px',
                    height: '60px',
                    fontSize: '20px',
                    backgroundColor: wl.colorPrimary,
                    color: 'white'
                }}
            />
        </Layout>
    );
}
