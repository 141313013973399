import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import Authentication from 'lib/Authentication';

import App from './components/App';

import 'moment/locale/pt-br';
import 'react-quill/dist/quill.snow.css';

Sentry.init({
    dsn: 'https://fcb0daa8436a1296d74b30d9f427727b@o4507810439823360.ingest.us.sentry.io/4507810444345344',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/(app\.gcondo\.com\.br|sindigest\.com\.br)\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

if(Authentication.isAuthenticated()){
    Sentry.setUser({
        username: Authentication.getUserName(),
        email: Authentication.getUserEmail(),
        client: Authentication.getClientManager().name
    });
}

ReactDOM.render(<App />, document.getElementById('root'));